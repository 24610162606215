import React from 'react';
import Checkbox from '../Checkbox';
import "./CheckboxGroup.scss";

export const CheckboxGroupWrapper = ({ children }) => {
	return (
		<div className="checkbox-group-wrapper">
			{children}
		</div>
	);
}

const CheckboxGroup = ({ list, masterCheckbox }) => {
	return (
		<div className="checkbox-group">
			<div className="master-checkbox">
				{masterCheckbox && masterCheckbox.length > 0 ? (
					<Checkbox label={masterCheckbox.name} checked={masterCheckbox.checked} onClick={masterCheckbox.onClick} disabled={masterCheckbox.disabled} />
				) : (
					masterCheckbox
				)}
			</div>
			<div className="checkbox-list">
				{list &&
					list.length > 0 &&
					list.map((li, idx) => {
						return (
							<div className="checkbox-list-wrapper">
								<Checkbox key={idx} label={li.label} checked={li.checked} onChange={li.onClick} disabled={li.disabled} />
							</div>
						)
					})}
			</div>
		</div>
	);
}
export default CheckboxGroup;