import React, { Component } from "react";
import { PageContentBanner } from "../Shared/Page/Page";
import "./CraigsLibraryPageContentBanner.scss";
import DateRangeInput from "./DateRangeInput";
import { withCraigsDocsFilters } from "./withCraigsDocs";
import TickerListSearchField from "../../components/Input/TickerListSearchField";

class CraigsLibraryPageContentBanner extends Component {
	render() {
		const { craigsDocsFilters, setCraigsDocsFilters } = this.props;
		const { ticker, from, to } = craigsDocsFilters;
		return (
			<PageContentBanner divider gap="m">
				<TickerListSearchField
					minWidth
					size="small"
					placeholder="Search"
					value={ticker}
					onChange={(ticker) => setCraigsDocsFilters({ ...craigsDocsFilters, ticker })}
					isClearable
					onClear={() => setCraigsDocsFilters({...craigsDocsFilters, ticker: null})}
				/>
				<DateRangeInput value={{ from, to }} onChange={({ from, to }) => setCraigsDocsFilters({ ...craigsDocsFilters, from, to })} />
			</PageContentBanner>
		);
	}
}

export default withCraigsDocsFilters(CraigsLibraryPageContentBanner);
