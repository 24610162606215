import React from 'react';
import { CheckCircle } from 'react-feather';
import Tooltip from '../popups/Tooltip';

const WatchlistIcon = ({ onWatchList, size, label, onClick, assetId, style, grouping }) => {


	return (
		<Tooltip label={label ? label : "On WatchList"}>
			<CheckCircle
				className={`watchlist-icon cursor-pointer ${onWatchList ? "colour-text-positive-dark" : "colour-text-icon-grey colour-hover-text-neutral-dark-60"}`}
				style={style}
				size={size}
			/>
		</Tooltip>
	);
}

export default WatchlistIcon;