import React,{ useState, useRef } from "react";
//import Editor, { images, tables, simpleInlineStyles } from "./lib/index";
import Editor from '@threeskye/editor-component-slate';
import './SlateEditor.scss';

const SlateEditor = (props) => {

	// Add the initial value when setting up our state.
	const initial = props.load;
	const [value, setValue] = useState(initial);

	return (
	<>
		<div className="threeskye-editor">
		<Editor 
			readOnly={props.readOnly}
			addins={() => props.addins}
			className="editor-wrapper"
			value={value || []}
			onChange={(bits) =>  {props.onChange(bits);setValue(bits);}}
			toolbar={props.toolbar || {type:"full"}}
			setToolbar={props.setToolbar}
			colourOptions={props.colourOptions}
			throwToaster={props.throwToaster}
		/>
		</div>
	</>
	)
}


export default SlateEditor;