import React, { Component } from 'react';
import { Upload } from 'react-feather';
import FileDrop from 'react-file-drop';
import "./Dropzone.scss";

class ExpandingDropzone extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dragging: false
		}
		this.onDrop = this.onDrop.bind(this)
	}

	onDrop(props){
		this.setState({ dragging: false })
		this.props.onDrop(props)
	}

	render() {
		const { dragging } = this.state;
		const { className, expandedZoneClass, smallZoneClass, uploading, showUploadingSpinner, onDrop, children, uploadingMessage } = this.props;
		return (
			<FileDrop
				onDrop={this.onDrop}
				onDragOver={(e) => {
					let event = e;
					event.stopPropagation();
					event.preventDefault();
				}}
				onFrameDragEnter={() => this.setState({ dragging: true })}
				onFrameDragLeave={() => this.setState({ dragging: false })}
			>
				<div
					className={`dropzone-wrapper${dragging ? " drag-active" : ""}${className ? " " + className : ""}${smallZoneClass ? " " + smallZoneClass : ""}`}
					onClick={() => this.fileInput.click()}
				>
					<div className={`dropzone${className ? " " + className : ""}${expandedZoneClass ? " " + expandedZoneClass : ""}`}>
						{uploading ? (
							<>
								{showUploadingSpinner && <div className="sp-circle" />}
								{uploadingMessage}
							</>
						) : (
							<>
								<Upload className="upload-icon" />
								<div className="dropzone-message">Upload file</div>
								<input
									type="file"
									style={{ display: "none" }}
									onChange={(e) => {
										onDrop(e.target.files);
									}}
									ref={(el) => (this.fileInput = el)}
									multiple
								/>
							</>
						)}
					</div>
					{children && children}
				</div>
				<div className={`drop-zone-lz${dragging ? " zone-active" : ""}`}>
					<Upload className="upload-icon" /> Drop to upload
				</div>
			</FileDrop>
		);
	}
}

export default ExpandingDropzone;