import React from 'react';
import DropDown from '../../../components/DropDown';
import DateRangeToggle from './DateRangeToggle'
import FlexWrapper from '../../FlexWrapper';

const AnalyticsToggleHeader = ({ value, values, setValue, dateRanges, selectedDateRange, setSelectedDateRange }) => {
	const others = values ? values.filter(v => v !== value) : [];

	const displayTimeFrame = (time) => {
		if (!time || typeof time === "string") return "All Time"
		let string = `${time.months ? time.months + " Months" : ""} ${time.days ? time.days + " Days" : ""} ${time.years ? time.years + " Years" : ""}`
		return string
	}

	return (
		<FlexWrapper className="analytics-header mb-l" fullWidth justify="space-between">
			<DropDown fixed value={<p className="font-weight-semi-bold">{value}</p>} transparent clickable>
				{others.map(o => <li key={o} onClick={() => setValue(o)}>{o}</li>)}
			</DropDown>
			{dateRanges && <DateRangeToggle value={displayTimeFrame(selectedDateRange)} onChange={setSelectedDateRange} dateRanges={dateRanges} />}
		</FlexWrapper >

	);
}

export default AnalyticsToggleHeader;