import React from 'react';
import { Database } from 'react-feather';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';

const MiniDataList = ({ data, suggestionText, restrictHeight }) => {

	const colWidths = [50 + "%", 50 + "%"];
	const headers = ["Label", "Value"];

	const dataMap = () => Object.keys(data).sort().map((key,idx) => {
		return (
			<tr key={idx}>
				<td>{key}</td>
				<td>{data[key]}</td>
			</tr>
		)
	})

	const dataLength = data && data.length; 
	const noData = !data || dataLength <= 0;
	const noSearchResults = false;
	
	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap rowSpan="1">
				<DetailSectionHeader icon={<Database />} header="Imported Data" />
				{!noData && (
					<>
						{/* @Andrew can you wire up pretty please */}
						{/* <SearchTermFilter
							size="small"
							width={xl ? "l" : l ? "m" : "s"}
							placeholder="Search data"
						/> */}
					</>
				)}
			</PageGridItem>
			<PageGridItem col="1 / span 6" rowSpan="3">
				{noData ? (
					<SuggestionMessage message={<>{suggestionText ? suggestionText : "No data to display."}</>} />
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table fixedHeaders restrictHeight={restrictHeight} headers={headers} colWidths={colWidths} dataMap={dataMap()} />
				)}
			</PageGridItem>
		</>
	);
}

export default withRouteChange(MiniDataList);