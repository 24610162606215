import React, { Component } from "react";
import LogoSrc from "../../../images/3skye-logo.png";

class Logo extends Component {
	render() {
		const { className, id, onClick} = this.props;
		const classNames = ["logo", className].filter((cn) => !!cn).join(" ");
		return <img src={LogoSrc} alt="logo" id={id} className={classNames} onClick={onClick} />;
	}
}

export default Logo;
