import React from 'react';
import TsLoading from '@threeskye/loading-icon/dist/LoadingIcon';
import "./LoadingIcon.scss";

const LoadingIcon = ({size, variant, centered}) => {
	return (
		<div className={`loading-icon-outer${variant ? " loading-icon-variant-" + variant : ""}${centered ? " loading-icon-centered" : ""}`}>
			<TsLoading size={size ? size : 72} />
		</div>
	)
}

export default LoadingIcon;