import React from "react";
import Checkbox from "./Checkbox";
import "./TickerItem.scss";
import { SortableElement } from "react-sortable-hoc";
import DragIndicatorIcon from "./Icons/DragIndicatorIcon";
import Tooltip from "./popups/Tooltip";
import { CheckCircle, Info } from "react-feather";

const TickerItem = SortableElement(({ ticker, tooltipMessage, hasData, onChange, checked, loading, className, tickerUploadedBy, tickerUploadedAt, tickerMessage, tickerStatus }) => (
	<div className={`ticker-item-container${className ? " " + className : ""}`}>
		<DragIndicatorIcon />
		<Checkbox checked={checked} onChange={onChange} loading={loading} />
		<div className={`ticker-detail-wrapper${tickerStatus ? " status-" + tickerStatus : ""}${checked ? " ticker-item-checked" : ""}`}>
			<div className="ticker-code">
				{ticker}
			</div>
			{/* time stamp + uploader tooltip */}
			<Tooltip maxWidth={300} disabled={!tickerMessage} label={tickerMessage ? tickerMessage : ""} padding="s">
				<div className="ticker-timestamp">
					{hasData ? <CheckCircle className="status-icon" /> : <Info className="status-icon" />}
					{hasData ? "Data available" : "Data Unavailable"}
				</div>
			</Tooltip>
		</div>
	</div>
));

export default TickerItem;