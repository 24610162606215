import React, { useState } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions from '../../../Utils/Utils';
import { dummyResearch, gicsSectors, dummyTickers } from '../../../Utils/DummyData';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import SectorPageDetails from './SectorPageDetails';
import MiniTickerList from '../../Shared/TablesAndLists/MiniTickerList';
import MiniResearchList from '../../Shared/TablesAndLists/MiniResearchList';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import { toast } from 'react-toastify';

const SectorPage = () => {
	const [editMode, setEditMode] = useState(false);
	const { width } = useWindowDimensions();

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true) }}
					duplicate={{ onClick: () => alert("Shadow Clone Jutsu 🙏") }}
					cancel={{ onClick: () => setEditMode(false) }}
					save={{ onClick: () => alert("Pak'nSave") }}
					remove={{ onClick: () => toast( "Ticker deleted")}}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="sector-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the sector details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<SectorPageDetails editMode={editMode} sector={gicsSectors[0]} />
						<PageGridDivider col="1 / span 6" />
						{/* Tickers */}
						<MiniTickerList header="Coverage" data={dummyTickers} />
					</PageContentBodyGrid>
				</PageGridItem>
				{/* Research section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						{/* Research List */}
						<MiniResearchList suggestionText="Published research for this sector will be listed here." data={dummyResearch} />
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}
export default SectorPage;