import React, { useEffect, useState } from 'react';
import SearchObjectFilter from '../../../components/Input/SearchObjectFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { useRemote, useStorage, minutesIntoReadableFormatWithSeconds, minutesIntoReadableFormat } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Clock, TrendingUp, BookOpen, Eye, Mail, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingIcon from '../../../components/LoadingIcon';
import TickerSectorItem from '../../../components/TickerSectorItem'
import { format } from 'date-fns';
import { ROUTE_PATHS, PAGES } from '../../../InternalApiApp';
import TruncatedString from '../../Shared/TruncatedString'


const AnalyticsAuthorPage = ({ changeRoute }) => {
    const remote = useRemote();
    const storage = useStorage()
    const { authorId } = useParams();

    // State
    const [author, setAuthor] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [metrics, setMetrics] = useState(null)
    const [allTickers, setAllTickers] = useState([])
    const [allGroups, setAllGrpups] = useState([])

    const [filteredData, setFilteredData] = useState([]);
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentFilter, setCurrentFilter] = useState("");
    const [sortingColumn, setSortingColumn] = useState({ column: "Time/Date", ascending: false })
    const [dataDisplaying, setDataDisplaying] = useState(null)

    useEffect(() => {
        if (!authorId) {
            setAuthor(null)
            setAnalytics(null)
            setLoading(false)
            return;
        }

        Promise.all([
            storage.getOrFetch("/analytics/aggregates/authors"),
            remote.get(`/analytics/aggregates/author/${authorId}`),
            storage.getOrFetch(`/crm/tickers`),
            storage.getOrFetch(`/crm/groupings/${1}`),

        ]).then(([fetchedAuthors, fetchedAnalytics, fetchedTickers, fetchedGroups]) => {
            const foundAuthor = fetchedAuthors && fetchedAuthors.find(a => a.email === authorId)
            const myMetrics = {};

            myMetrics.avgSessionTime = "-" // foundAuthor.minutes / foundAuthor.numLogins
            myMetrics.tickersCoverted = foundAuthor.tickersCovered 
            myMetrics.researchPublished = foundAuthor.researchPublished
            myMetrics.portalViews = foundAuthor.portalViews

            console.log("foundAuthor", foundAuthor)
            if (foundAuthor) setAuthor(foundAuthor)
            setAnalytics(fetchedAnalytics.sort((a, b) =>  b.publishedDate.localeCompare(a.publishedDate)))
            setAllTickers(fetchedTickers)
            setAllGrpups(fetchedGroups.groups)
            setMetrics(myMetrics)

        }).then(() => { 
            setLoading(false)
        })
    }, [authorId]);

    // Sorting
    useEffect(() => {
        sortTable(null)
    }, [analytics, filteredData, hasSearchTerm])

    const sortTable = (column) => {
        if (column === null) {
            setDataDisplaying(hasSearchTerm ? filteredData : analytics)
            return
        }
        let tableData = hasSearchTerm ? filteredData : analytics
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        switch (column) {
            case "Time/Date":
                tableData.sort((a, b) => ascending ? a.publishedDate.localeCompare(b.publishedDate) : b.publishedDate.localeCompare(a.publishedDate))
                break;
            case "Ticker/Group":
                tableData.sort((a, b) => ascending ? a.tickerSymbol.localeCompare(b.tickerSymbol) : b.tickerSymbol.localeCompare(a.tickerSymbol))
                break;
            case "Title":
                tableData.sort((a, b) => ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title))
                break;
            case "Minutes":
                tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
                break;
            case "Read":
                tableData.sort((a, b) => ascending ? a.emailsRead - b.emailsRead : b.emailsRead - a.emailsRead)
                break;
            case "PortalViews":
                tableData.sort((a, b) => ascending ? a.portalViews - b.portalViews : b.portalViews - a.portalViews)
                break;
            case "PDFDownloads":
                tableData.sort((a, b) => ascending ? (a.portalPdfDownloads + a.emailPdfDownloads) - (b.portalPdfDownloads + b.emailPdfDownloads) : (b.portalPdfDownloads + b.emailPdfDownloads) - (a.portalPdfDownloads + a.emailPdfDownloads))
                break;
            case "ModelDownloads":
                tableData.sort((a, b) => ascending ? a.dataDownloads - b.dataDownloads : b.dataDownloads - a.dataDownloads)
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        setDataDisplaying(tableData)
    }

    // Table data   
    const colWidths = ["150px", "200px", "260px", "70px", "70px", "70px", "70px", "70px"]
    const headers = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Time/Date")} >
                    Time/Date
                    {sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Ticker/Group")} >
                    Ticker/Group
                    {sortingColumn && sortingColumn.column === "Ticker/Group" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Ticker/Group" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Title")} >
                    Title
                    {sortingColumn && sortingColumn.column === "Title" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Title" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <Tooltip label="Minutes">
                    <div className='sorting-header' onClick={() => sortTable("Minutes")}>
                        <Clock className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Read">
                    <div className='sorting-header' onClick={() => sortTable("Read")}>
                        <Mail className="colour-text-purple" size={16} />
                        {sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Portal Views">
                    <div className='sorting-header' onClick={() => sortTable("PortalViews")}>
                        <Eye className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="PDF Downloads">
                    <div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
                        <PdfIcon noHover className="cursor-pointer" size={16} />
                        {sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Model Downloads">
                    <div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
                        <XlsIcon noHover className="cursor-pointer" size={16} />
                        {sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        }
    ]

    const dataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        return (
            // TODO: on row click go one level deeper 
            <tr key={idx} className="cursor-pointer" onClick={() => goToNote(datum)}>
                {/* User details */}
                <td>{format(new Date(datum.publishedDate), "dd/MM/yyyy")}</td>
                <td>
                    <TickerSectorItem image={getImage(datum)} label={datum.tickerSymbol} />
                </td>
                <td style={{ paddingRight: "1.5rem" }}><TruncatedString string={datum.title} /></td>
                <td className="text-align-center">{minutesIntoReadableFormat(datum.minutes)}</td>
                <td className="text-align-center">{datum.emailsRead}</td>
                <td className="text-align-center">{datum.portalViews}</td>
                <td className="text-align-center">{datum.portalPdfDownloads + datum.emailPdfDownloads}</td>
                <td className="text-align-center">{datum.dataDownloads}</td>
            </tr>
        )
    })


    function getImage(note) {
        if (allTickers && allTickers.length > 0 && note.tickerSymbol && note.tickerSymbol.includes(".")) {
            const ticker = allTickers.find(t => t.ric && t.ric === note.tickerSymbol)
            return ticker ? `/api/public/images/ticker/${ticker.id}.png` : null
        } else if (allGroups && allGroups.length > 0 && note.tickerSymbol && !note.tickerSymbol.includes(".")) {
            const group = allGroups.find(g => g.groupName === note.tickerSymbol)
            return group ? `/api/public/images/group/${group.id}.png` : null
        } else return null
    }

    function goToNote(note) {
        if (note.tickerSymbol.includes(".")) {
            const ticker = allTickers && allTickers.find(t => t.ric === note.tickerSymbol)
            if (ticker) changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/" + ticker.id + "/research/" + note.noteId)
        } else {
            const group = allGroups.find(g => g.groupName === note.tickerSymbol)
            if (group) changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/group/" + group.id + "/research/" + note.noteId)
        }
    }


    if (!author) {
        return (
            <Page noBanner showScrollbar>
                <PageContentBodyGrid showScrollbar>
                    <PageGridItem colSpan="12">
                        <LoadingIcon centered />
                    </PageGridItem>
                </PageContentBodyGrid>
            </Page>
        )
    }

    return (
        <Page noBanner showScrollbar>
            {/* Modal */}
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="l">
                        {author ?
                            <FlexWrapper gap="xs" direction="column" align="flex-start">
                                <p className="font-weight-semi-bold">{author.name}</p>
                                <FlexWrapper direction="row" gap="s">
                                    <Tooltip label={author.email}>
                                        <Mail className='colour-text-neutral-dark-40 informative-icon' size={16} />
                                    </Tooltip>
                                </FlexWrapper>
                            </FlexWrapper> : loading ? <LoadingIcon size={32} /> : ""}
                        <Divider vertical height={40} />
                        <AnalyticsMetricFilter
                            onClick={() => null}
                            active={currentFilter === "avgSessionTime"}
                            inactive={currentFilter !== "" && currentFilter !== "avgSessionTime"}
                            label="Avg Session Time"
                            status="read"
                            icon={<Clock color='#0E8EFD' />}
                            count={metrics?.avgSessionTime && !isNaN(metrics.avgSessionTime) ? minutesIntoReadableFormatWithSeconds(metrics.avgSessionTime) : "-"}
                            style={{ cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => null}
                            active={currentFilter === "tickersCovered"}
                            inactive={currentFilter !== "" && currentFilter !== "tickersCovered"}
                            label="Tickers Covered"
                            status="read"
                            icon={<TrendingUp />}
                            count={metrics?.tickersCoverted}
                            style={{ cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => null}
                            active={currentFilter === "researchPublished"}
                            inactive={currentFilter !== "" && currentFilter !== "researchPublished"}
                            label="Research Published"
                            icon={<BookOpen noHover />}
                            count={metrics?.researchPublished}
                            style={{ cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => null}
                            active={currentFilter === "portalViews"}
                            inactive={currentFilter !== "" && currentFilter !== "portalViews"}
                            label="Portal Views"
                            icon={<Eye />}
                            count={metrics?.portalViews}
                            style={{ cursor: "default" }}
                        />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridDivider colSpan="12" />
                <PageGridItem>
                    <SearchObjectFilter
                        size="small"
                        width="l"
                        placeholder={`Search`}
                        isClearable
                        dataSet={analytics}
                        setFilteredData={setFilteredData}
                        path={["ticker", "title"]}
                        subProperty={"note"}
                        hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                    />
                </PageGridItem>
                <PageGridItem colSpan="12" style={{ paddingTop: "0.5rem" }}>
                    <Table
                        minWidth={1000}
                        tableStyle={{ maxWidth: 1200 }}
                        colWidths={colWidths}
                        headers={headers}
                        dataMap={dataMap(dataDisplaying)}
                        noResults={{ message: "No research found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(AnalyticsAuthorPage);