import React, { Component } from 'react';
import { ChevronLeft } from 'react-feather';
import "./CollapseButton.scss";

const CollapseButton = ({collapsed, onClick, outer}) => {
	return (
		<div className={`collapse-button-wrapper${collapsed ? " collapsed" : ""}${outer ? " outer-collapse" : ""}`} onClick={onClick}>
			<ChevronLeft className="colour-text-neutral-light" size={18} />
		</div>
	);
}

export default CollapseButton;