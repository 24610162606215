import React, { Component } from 'react';
import { X } from 'react-feather';
import CreatableSelect from 'react-select/creatable';
import "../components/Input/SearchField.scss";

class CreatableInputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			values: props.values ? props.values : [],
			inputValue: ""
		};

		// this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.values !== prevProps.values) {
			this.setState({ values: this.props.values });
		}
	}

	handleChange = (values) => {
		this.setState({ values }, () => this.props.onChange(this.state.values));
	};

	handleInputChange = (inputValue) => {
		this.setState({ inputValue }, () => this.props.onInputChange(this.state.inputValue));
	};

	// handleKeyDown = (event) => {
	// 	const { inputValue, values } = this.state;

	// 	if (!inputValue) return;
	// 	if (values === null) {
	// 		this.setState({ values: [] })
	// 	}
	// 	switch (event.key) {
	// 		case "Enter":
	// 		case "Tab":
	// 			if (this.props.isMulti) this.setState({ inputValue: "", values: [...values, { label: inputValue, value: inputValue }] }, () => this.props.onKeyDown(this.state.values));
	// 			else this.setState({ inputValue: "", values: { label: inputValue, value: inputValue } }, () => this.props.onKeyDown(this.state.values));
	// 			event.preventDefault();
	// 		/* falls through */
	// 		default:
	// 	}
	// };

	render() {
		const { inputValue, values } = this.state;
		return (
			<CreatableSelect
				isMulti={this.props.isMulti}
				className={`search-select-field react-select-container text-field-style creatable-select-control${this.props.className ? " " + this.props.className : ""}`}
				placeholder={this.props.placeholder ? this.props.placeholder : null}
				classNamePrefix="search-select-field"
				inputValue={inputValue}
				onChange={this.handleChange}
				onInputChange={this.handleInputChange}
				// onKeyDown={this.handleKeyDown}
				value={values}
				disabled={this.props.disabled}
				options={this.props.options}
				menuPosition="fixed"
				menuPortalTarget={document.body} 
    			styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
				components={{ ...this.props.components, DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => <X /> }}
			/>
		);
	}
}

export default CreatableInputField;