import React from 'react';
import Modal from './Modal';

const RemoveConfirmModal = ({label, handleClose, confirmButton, cancelButton, header}) => {
	return (
		<Modal header={header ? header : "Confirm Removal"} handleClose={handleClose} cancelButton={{...cancelButton}} confirmButton={{ label: "Yes, remove now", variant: "danger", ...confirmButton}}>
			Are you sure you want to remove this {label}? This cannot be undone.
		</Modal>
   );
}
export default RemoveConfirmModal;