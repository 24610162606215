import React, { Component } from "react";

//Assumes wrapped component is forward ref'd
const withInfiniteScroll = function (WrappedComponent) {
	return class extends Component {
		scrollableElementRef = React.createRef();
		scrollableElementChildRef = React.createRef();

		constructor(props) {
			super(props);
			this.onScroll = this.onScroll.bind(this);
			this.setFetchMoreDataFunc = this.setFetchMoreDataFunc.bind(this);
			this.hasScrolledToBottom = this.hasScrolledToBottom.bind(this);
		}

		componentWillUnmount() {
			this.scrollableElementRef.current.removeEventListener("scroll", this.onScroll);
		}

		onScroll() {
			if (this.fetchData) {
				if (this.hasScrolledToBottom()) {
					this.fetchData();
				}
			}
		}

		setFetchMoreDataFunc(func) {
			this.fetchData = func;
			this.scrollableElementRef.current.addEventListener("scroll", this.onScroll);
		}

		hasScrolledToBottom() {
			return this.scrollableElementChildRef.current.getBoundingClientRect().bottom <= this.scrollableElementRef.current.getBoundingClientRect().bottom;
		}

		render() {
			const { setFetchMoreDataFunc, scrollableElementRef, scrollableElementChildRef } = this;
			return <WrappedComponent {...this.props} {...{ setFetchMoreDataFunc, scrollableElementRef, scrollableElementChildRef }} />;
		}
	};
};

export default withInfiniteScroll;
