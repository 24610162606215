import React from 'react';

const DetailFieldList = (props) => {
	const { label, value, bold  } = props;
	return (
		<div className="detail-field-wrapper">
			<React.Fragment>
				{label ? <label className="colour-text-label-grey transition-fade-up">{label}</label> : ""}
				<ul className={`${label ? "mt-s " : ""}transition-fade-up-slow${bold ? " font-weight-semi-bold" : ""}`} style={{ wordBreak: "break-word" }}>
					{value && value.map((v,idx) => <li key={idx}>{v}</li>)}
				</ul>
			</React.Fragment>
		</div>
	);
}

export default DetailFieldList;