import React from 'react';
import Divider from '../../../components/Divider';
import PageGridItem from './PageGridItem';

const PageGridDivider = (props) => {
	return (
		<PageGridItem {...props}>
			<Divider noMargin />
		</PageGridItem>
   );
}
export default PageGridDivider;
