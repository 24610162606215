import React from 'react';

const RecommendationSlider = (props) => {

	const config = {
		"widthNum":118,
		"width": "118px",
		"height": "8px",
		"heightNum": 8,
		"segments": [
			"#E8305A",
			"#FFB81C",
			"#09B1AA"
		],
		"labels": [
			"Sell",
			"Hold",
			"Buy"
		],
		"labelStyle": "meter-label",
		"labelStyleActive": "meter-label-active",
		"min":1,
		"max":5,
		"value": {
			"value": "${RecommendationMean}"
		},
		"inverted":true
		
	}

	const {recommendation} = props;
	const recommendationNum = parseFloat(recommendation);
	const normalisedRecommendation = recommendationNum - config.min;
	const numPerSector = parseFloat((config.max - config.min) / 3);

	let index = 0;
	if (normalisedRecommendation > numPerSector) {
		index = 1;
	}
	if (normalisedRecommendation > numPerSector * 2) {
		index = 2;
	}

	const colour = config.segments[index];
	const label = config.labels[index];

	const bits = [];
	const alignmentStyle = {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center"
	}
	const style = {
		width: config.width,
		height: config.height,
		backgroundColor: colour,
		borderRadius: config.height,
		position: "relative"
	}

	console.log("Arrow left is ", (config.widthNum * ( normalisedRecommendation) / (config.max - config.min)) + "px")

	const arrowStyle = {
		position: 'absolute',
		left: ((config.widthNum * ( normalisedRecommendation) / (config.max - config.min)) - 6) + "px",
		width: 0,
		height: 0,
		top: ((config.heightNum / 2) - 2) + 'px',
		borderLeft: '6px solid transparent',
		borderRight: '6px solid transparent', 	 
		borderBottom: '12px solid white'
	}

	const arrowOverlayStyle = {
		position: 'absolute',
		left: ((config.widthNum * ( normalisedRecommendation) / (config.max - config.min)) - 6) + "px",
		width: 0,
		height: 0,
		top: ((config.heightNum / 2)) + 'px',
		borderLeft: '6px solid transparent',
		borderRight: '6px solid transparent',
		borderBottom: '12px solid black'
	}

	bits.push(<div style={alignmentStyle}>
		<div style={{"margin-right":"1rem"}}>{label}</div>
		<div style={style}>
			<div style={arrowStyle}/>
			<div style={arrowOverlayStyle}/>
		</div>
	</div>);
	return <>{bits}</>

}

export default RecommendationSlider;
