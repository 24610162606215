import React from 'react';
import AnalyticsCounter from '../../../components/Analytics/AnalyticsCounter';
import Tooltip from '../../../components/popups/Tooltip';
import { getPercentage } from '../../../Utils/Utils';
import "./HorizontalStackedChart.scss";
import "../../../styles/ChartColours.scss";

const HorizontalStackedChart = ({ data, tooltips }) => {
	const total = data.reduce((accumulator, object) => {
		return accumulator + object.value;
	}, 0);

	return (
		<div className="stacked-bar-container">
			{/* Metrics */}
			{data &&
				data.length >= 0 &&
				data.map((d, idx) => <AnalyticsCounter valueClass={`colour-chart-text-${idx + 1}`} key={idx + "-" + d.name} label={d.name + " (" + d.value + ")"} count={getPercentage(d.value, total)} />)}
			{/* Bar segments */}
			<div className="stacked-bar-segments-wrapper">
				{data &&
					data.length >= 0 &&
					data.map((d, idx) => (
						<Tooltip disabled={!tooltips} label={d.name} outerStyle={{ width: getPercentage(d.value, total, 1) }}>
							<div key={idx + "-" + d.value} style={{ backgroundColor: d.colour, height: 56 }} className={`stacked-bar-segment colour-chart-${idx + 1}`} />
						</Tooltip>
					))}
			</div>
		</div>
	);
};

export default HorizontalStackedChart;