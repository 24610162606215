import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Edit, Edit2, Eye, Send, Trash2 } from 'react-feather';
import TableActionIcon from '../../components/Table/TableActionIcon';
import TickerSectorItem from '../../components/TickerSectorItem';
import PdfIcon from '../../images/icons/PdfIcon';
import XlsIcon from '../../images/icons/XlsIcon';
import PublicationSettings from '../CraigsLibraryPage/PublicationManagement/PublicationSettings';
import StatusToken from '../../components/StatusTokens';
import FlexWrapper from '../FlexWrapper';
import Divider from '../../components/Divider';
import DraftNotePreviewModal from '../../components/Modals/DraftNotePreviewModal';
import ConfirmActionModal from '../../components/Modals/ConfirmActionModal';
import { toast, toastInfo, toastSuccess } from '../../components/popups/Toast';
import withRouteChange from '@threeskye/route-change';
import preview from './fundfactsheetpreview.png';
import { showConfirmActionModal, useStorage } from '../../Utils/Utils';

const RequestsTableRow = ({ data, idx, tickers, expandedRows, setExpandedRows, getImage, models, modelsLoading, changeRoute, refreshList }) => {
	const [document, setDocument] = useState({});
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [imageKey, setImageKey] = useState(Date.now());
	const [confirmActionModal, setConfirmActionModal] = useState(null);
	const [me, setMe] = useState({});
	
	const perms = me?.perms || [];
	const storage = useStorage();

	const actions = [
		{
			label: "Confirm Approval",
			variant: "positive",
			disabled: !perms.includes("REVIEW_COMPLIANCE"), 
			action: () => {
				showConfirmActionModal("approveCompliance", setConfirmActionModal, data?.template, data?.ticker, refreshList); setShowPreviewModal(false)
			}
		},
		{
			label: "Confirm & Publish",
			variant: "positive",
			disabled: !perms.includes("REVIEW_COMPLIANCE") || !perms.includes("WORKSPACE_PUBLISH"), 
			action: () => {
				showConfirmActionModal("approveComplianceAndPublish", setConfirmActionModal, data?.template, data?.ticker, refreshList); setShowPreviewModal(false)
			}
		},
		{
			label: "Deny Approval",
			variant: "danger",
			disabled: !perms.includes("REVIEW_COMPLIANCE"), 
			action: () => {
				showConfirmActionModal("rejectCompliance", setConfirmActionModal, data?.template, data?.ticker, refreshList); setShowPreviewModal(false)
			}
		}
	]

	const updateVisibility = (bool) => {
		let newDocument = { ...document, publishedToPortal: bool }
		setDocument(newDocument)
	}

	const updateImageKey = () => {
		setImageKey(Date.now())
	}

	useEffect(() => {
		setDocument(data)
		storage.getOrFetch(`/crm/me`).then(setMe)
	}, [data])

	const handlePublishClick = () => {
		confirmActionModal(null);
		toastSuccess("Draft published and sent");
	}

	const handleDeleteClick = () => {
		confirmActionModal(null);
		toastInfo("Draft note deleted");
	}


	return (
		<>
			{showPreviewModal && <DraftNotePreviewModal data={data} compliance handleClose={() => setShowPreviewModal(false)} actions={actions} />}
			{confirmActionModal && <ConfirmActionModal {...confirmActionModal} />}
			<tr key={idx} className={expandedRows && document && expandedRows.includes(document.docId) ? "library-row-selected" : ""} onClick={() => setShowPreviewModal(true)}>
				<td>
					{
						document && document.requestedAt
							? <p style={{ display: "flex", alignItems: "center" }}>
								{format(parseISO(document.requestedAt), "do MMM yyyy")}
								<span style={{ display: "inline-block", width: "3px", height: "3px", borderRadius: "5px", backgroundColor: "black", margin: "0px 5px" }} />
								{format(parseISO(document.requestedAt), "hh:mm aaa")}
							</p>
							: "-"
					}
				</td>
				<td>
					{
						document && document.lastEdited
							? <p style={{ display: "flex", alignItems: "center" }}>
								{format(parseISO(document.lastEdited), "do MMM yyyy")}
								<span style={{ display: "inline-block", width: "3px", height: "3px", borderRadius: "5px", backgroundColor: "black", margin: "0px 5px" }} />
								{format(parseISO(document.lastEdited), "hh:mm aaa")}
							</p>
							: "-"
					}
				</td>
				<td>
					<div className="tsi-details">
						<div className="tsi-label">
							{document && document.ticker}
						</div>
					</div>
				</td>
				<td className="research-title">
					<p>{document && document.template ? document.template : document && document.type ? document.type : ""}</p>
				</td>
				<td>
					<p>{document && document.requester && document.requester.name}</p>
				</td>
				{/* Row Actions */}
				<td>
					<FlexWrapper gap="l">
						<TableActionIcon colour="primary" noMargin icon={<Eye />} toolTip="Preview Note" onClick={() => setShowPreviewModal(true)} />
						<TableActionIcon
							toolTipDisabled={document.status == "pending"}
							disabled={true}
							colour="primary"
							noMargin
							icon={<Edit2 />}
							toolTip="Edit Note"
							onClick={() => changeRoute(`/editor?template=${data?.type}&asset=${data?.ticker}`)}
						/>
						<Divider className="mr-m" vertical height={32} />
						<TableActionIcon
							toolTipDisabled={document.status == "pending"}
							disabled={document.status == "pending"}
							colour="positive"
							noMargin
							icon={<Send />}
							toolTip="Publish Note"
							onClick={() => { showConfirmActionModal("approveComplianceAndPublish", setConfirmActionModal, data?.template, data?.ticker, refreshList); setShowPreviewModal(false) }}
						/>
						<TableActionIcon colour="danger" noMargin icon={<Trash2 />} toolTip="Remove from Drafts" onClick={() => { showConfirmActionModal("rejectCompliance", setConfirmActionModal, data?.template, data?.ticker, refreshList); setShowPreviewModal(false) }} />
					</FlexWrapper>
				</td>
			</tr>
		</>
	);
}

export default withRouteChange(RequestsTableRow);