import React, { useEffect, useState } from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useRemote, minutesIntoReadableFormat } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Clock, User, Eye, Mail, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import FlexWrapper from '../../FlexWrapper'
import LoadingIcon from '../../Shared/LoadingIcon';

const AnalyticsCompaniesList = ({ changeRoute }) => {
	const remote = useRemote();

	// State
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [metrics, setMetrics] = useState(null)
	const [sortingColumn, setSortingColumn] = useState({ column: "Minutes", ascending: false })
	const [dataDisplaying, setDataDisplaying] = useState(null)

	useEffect(() => {
		remote.get("/analytics/companies").then((fetchedCompanies) => {

			if (fetchedCompanies && fetchedCompanies.length > 0) {
				const companiesActivity = fetchedCompanies.map(company => {
					console.log("company", company) 
					return {
						organisationName: company.organisationName,
						organisationId: company.organisationId,
						activity: company.contacts.reduce((total, contact) =>
							total + contact.dataDownloads + contact.portalPdfDownloads + contact.emailsRead + contact.portalViews + contact.minutes + contact.emailPdfDownloads, 0),
						minutes: company.contacts.reduce((total, contact) => total + contact.minutes, 0),
						pdfDownloads: company.contacts.reduce((total, contact) => total + contact.emailPdfDownloads + contact.portalPdfDownloads, 0),
						modelDownloads: company.contacts.reduce((total, contact) => total + contact.dataDownloads, 0)
					}
				});

				const myMetrics = {}
				const mostUsers = fetchedCompanies.toSorted((a, b) => b.contacts.length - a.contacts.length)[0]
				
				// const mostActive = 
				console.log("companiesActivity", companiesActivity)
				myMetrics.totalCompanies = fetchedCompanies.length
				myMetrics.mostUsers = { company: mostUsers && mostUsers.organisationName, users: mostUsers && mostUsers.contacts.length }
				myMetrics.mostActiveCompany = companiesActivity.toSorted((a, b) => b.activity - a.activity)[0]

				const sortedCompanies = fetchedCompanies.toSorted((a, b) => b.contacts.reduce((a, c) => c.minutes + a, 0) - a.contacts.reduce((a, c) => c.minutes + a, 0))
				setData(sortedCompanies)
				setMetrics(myMetrics)
			}

		}).then(() => setLoading(false))
	}, [])

	// Sorting
	useEffect(() => {
		sortTable(null)
	}, [data, filteredData])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : data)
			return
		}
		let tableData = hasSearchTerm ? filteredData : data
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true

		switch (column) {
			case "Company":
				tableData.sort((a, b) => ascending ? a.organisationName.localeCompare(b.organisationName) : b.organisationName.localeCompare(a.organisationName))
				break;
			case "Location":
				tableData.sort((a, b) => {
					const comp1 = a.location || ""
					const comp2 = b.location || ""
					return ascending ? comp1.localeCompare(comp2) : comp2.localeCompare(comp1)
				})
				break;
			case "Read":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.reduce((a, c) => c.emailsRead + a, 0);
					const comp2 = b.contacts.reduce((a, c) => c.emailsRead + a, 0);
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			case "PortalViews":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.reduce((a, c) => c.portalViews + a, 0);
					const comp2 = b.contacts.reduce((a, c) => c.portalViews + a, 0);
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			case "PDFDownloads":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.reduce((a, c) => c.emailPdfDownloads + c.portalPdfDownloads + a, 0);
					const comp2 = b.contacts.reduce((a, c) => c.emailPdfDownloads + c.portalPdfDownloads + a, 0);
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			case "ModelDownloads":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.reduce((a, c) => c.dataDownloads + a, 0);
					const comp2 = b.contacts.reduce((a, c) => c.dataDownloads + a, 0);
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			case "Minutes":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.reduce((a, c) => c.minutes + a, 0);
					const comp2 = b.contacts.reduce((a, c) => c.minutes + a, 0);
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			case "Users":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.length
					const comp2 = b.contacts.length
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			case "UsersWithPortalAccess":
				tableData.sort((a, b) => {
					const comp1 = a.contacts.reduce((a, c) => (c.contact.hasPortalAccess ? 1 : 0) + a, 0)
					const comp2 = b.contacts.reduce((a, c) => (c.contact.hasPortalAccess ? 1 : 0) + a, 0)
					return ascending ? comp1 - comp2 : comp2 - comp1
				})
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	// Table data
	const colWidths = ["280px", "180px", "90px", "70px", "70px", "70px", "70px", "70px", "70px"];
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Company")} >
					Company
					{sortingColumn && sortingColumn.column === "Company" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Company" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			className: "text-align-center"
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Location")} >
					Headquarters
					{sortingColumn && sortingColumn.column === "Location" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Location" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Minutes">
					<div className='sorting-header' onClick={() => sortTable("Minutes")}>
						<Clock className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Users">
					<div className='sorting-header' onClick={() => sortTable("Users")}>
						<User className="colour-text-purple" size={16} />
						{sortingColumn && sortingColumn.column === "Users" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Users" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Users with Portal Access">
					<div className='sorting-header' onClick={() => sortTable("UsersWithPortalAccess")}>
						<User className="colour-text-positive" size={16} />
						{sortingColumn && sortingColumn.column === "UsersWithPortalAccess" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "UsersWithPortalAccess" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Read">
					<div className='sorting-header' onClick={() => sortTable("Read")}>
						<Mail className="colour-text-purple" size={16} />
						{sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<div className='sorting-header' onClick={() => sortTable("PortalViews")}>
						<Eye className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
						<PdfIcon noHover size={16} />
						{sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
						<XlsIcon noHover size={16} />
						{sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		}
	];

	const dataMap = (data) => data && data.map((datum, idx) => {
		const portalViews = datum.contacts.reduce((a, c) => c.portalViews + a, 0);
		const pdfDownloads = datum.contacts.reduce((a, c) => c.emailPdfDownloads + c.portalPdfDownloads + a, 0);
		const emailsRead = datum.contacts.reduce((a, c) => c.emailsRead + a, 0);
		const dataDownloads = datum.contacts.reduce((a, c) => c.dataDownloads + a, 0);
		const minutes = datum.contacts.reduce((a, c) => c.minutes + a, 0);
		const usersWithpPortalAccess = datum.contacts.reduce((a, c) => (c.contact.hasPortalAccess ? 1 : 0) + a, 0)

		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/companies/company/" + datum.organisationId)}>
				{/* User details */}
				<td>{datum.organisationName}</td>
				<td>{datum.location || "-"}</td>
				{/* Analytics icons */}
				<td className="text-align-center">{minutesIntoReadableFormat(minutes)}</td>
				<td className="text-align-center">{datum.contacts.length}</td>
				<td className="text-align-center">{usersWithpPortalAccess}</td>
				<td className="text-align-center">{emailsRead}</td>
				<td className="text-align-center">{portalViews}</td>
				<td className="text-align-center">{pdfDownloads}</td>
				<td className="text-align-center">{dataDownloads}</td>
			</tr>
		);
	});

	return (
		<Page noBanner showScrollbar>
			{/* Modal */}
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="xl" style={{ paddingBottom: "1rem" }}>
						<FlexWrapper gap="xs" direction="column" align="flex-start" >
							<label className='total-metrics-label'>Total Companies</label>
							{loading ? <LoadingIcon size={32} /> : <h1 className='total-metrics-value'>{metrics.totalCompanies}</h1>}
						</FlexWrapper>
						<Tooltip label={metrics && metrics.mostUsers.users + " users"}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Most Users</label>
								{loading ? <LoadingIcon size={32} /> : <h1 className='total-metrics-value'>{metrics.mostUsers.company}</h1>}
							</FlexWrapper>
						</Tooltip>
						<Tooltip label={metrics && minutesIntoReadableFormat(metrics.mostActiveCompany.minutes) /*+ "  " + metrics.mostActiveCompany.pdfDownloads + " PDF Downloads " + metrics.mostActiveCompany.modelDownloads + " Model Downloads"*/}>
							<FlexWrapper gap="xs" direction="column" align="flex-start" >
								<label className='total-metrics-label'>Most Active Company</label>
								{loading ? <LoadingIcon size={32} /> : <h1 className='total-metrics-value'>{metrics.mostActiveCompany.organisationName}</h1>}
							</FlexWrapper>
						</Tooltip>
					</FlexWrapper>
					<PageGridDivider />
					<PageGridItem style={{ paddingTop: "1.5rem" }}>
						<SearchTermFilter
							size="small"
							width="l"
							placeholder={`Search companies ${loading ? "" : "(" + data.length + ")"}`}
							isClearable
							dataSet={data}
							setFilteredData={setFilteredData}
							path={["organisationName"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					</PageGridItem>
					<div className="table-title">All Companies</div>
					<Table
						minWidth={1000}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(dataDisplaying)}
						noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsCompaniesList);