import React, { Component } from 'react';
import "./SideBarFooter.scss";
import Button from "../../Buttons/Button";
import withRouteChange from '@threeskye/route-change';

class SideBarFooter extends Component {
	state = {previewLoading: false}
	render() {
		const { saving, publishEnabled, publish, publishOverride, publishing, preview, previewLoading, requiresReview, publishWithoutReviewEnabled } = this.props;
		return (
			<div className="sidebar-footer fixed">
				<div className="sidebar-footer-content border-default-top">
					<div className="sidebar-footer-buttons">
						<Button fullWidth onClick={preview} className={`mb-m ${saving ? " button-saving" : ""}`}  disabled={previewLoading} variant="secondary">{previewLoading ? <><div className="sp-circle" /> Generating</> : "Preview" }</Button>
						<Button fullWidth onClick={publish} variant="positive" disabled={!publishEnabled || publishing}>{publishing ? <><div className="sp-circle" />Publishing ...</> : "Publish"}</Button>
					</div>
					{/* Footer buttons */}
					{requiresReview && <Button fullWidth hover="default" variant="transparent" disabled={!publishWithoutReviewEnabled} className="mt-m" text="Publish without review" onClick={publishOverride}>Publish Without Review</Button>}
				</div>
			</div>
		);
	}
}

export default withRouteChange(SideBarFooter);