import React from 'react';
import "./TextArea.scss";
import TextFieldLabel from './TextFieldLabel';

const TextArea = (props) => {
	const { className, cols, rows, value, label, placeholder, resize, onChange, maxlength, forwardRef, style, onInput, onFocus } = props;
	let styleRules = style ? { ...style, resize: resize ? resize : "none" } : { resize: resize ? resize : "none" }
	return (
		<div className="text-area-container">
			{label && <TextFieldLabel>{label}</TextFieldLabel>}
			<textarea
				onInput={onInput}
				onFocus={onFocus}
				ref={forwardRef}
				value={value}
				maxLength={maxlength}
				onChange={onChange}
				style={styleRules}
				placeholder={placeholder ? placeholder : "Text...."}
				className={`text-area-field${className ? " " + className : ""}`}
				cols={cols ? cols : "30"}
				rows={rows ? rows : "5"}
			/>
		</div>
	)
}


export default TextArea;