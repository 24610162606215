import React, { useEffect, useState } from 'react';
import { Edit2, Plus, Save, X } from 'react-feather';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ModelAssetClassTable from './ModelAssetClassTable';
import "./ModelPortfolioPage.scss"
import { useRemote } from '../../../Utils/Utils';
import Divider from '../../../components/Divider';
import FlexWrapper from '../../FlexWrapper';
import DetailField from '../../Shared/DetailSection/DetailField';

const ModelPortfolioPage = () => {
	const [model, setModel] = useState({})
	const [loading, setLoading] = useState(true)
	const [editingClassName, setEditingClassName] = useState(null)
	const [showCreateClassForm, setShowCreateClassForm] = useState(false)
	const [noModels, setNoModels] = useState(false);
	const [newAssetClassName, setNewAssetClassName] = useState("")

	const remote = useRemote();

	useEffect(() => {
		getFreshData()
	}, [])

	const getFreshData = () => {
		remote.get("/crm/groupings").then(groupings => {
			if (groupings && groupings.length) {
				const modelPortfolios = groupings.filter(g => g.groupName === 'Model Portfolio');
				if (modelPortfolios.length) {
					remote.get(`/crm/groupings/${modelPortfolios[0].id}`).then(modelGrouping => {
						let groupsAssets = modelGrouping.groups.map((group) => {
							return remote.get(`/crm/groupings/${modelPortfolios[0].id}/${group.id}`)
						})
						Promise.all(groupsAssets).then((assets) => {
							let groupsWithAssets = modelGrouping.groups.map((group, idx) => { return { ...group, assets: assets[idx] } })
							let modelGroupingWithAssets = { ...modelGrouping, groups: groupsWithAssets }
							setModel(modelGroupingWithAssets)
						})
						setLoading(false);
					})
				} else {
					setLoading(false);
					setNoModels(true);
				}
			}
		})
	}

	const updateAssetClassName = (name, id) => {
		remote.put(`/crm/groupings/${model.id}/${id}`, { "groupName": name })
		.then(() => {
			getFreshData()
		})
	}

	const createAssetClass = (name) => {
		remote.post(`/crm/groupings/${model.id}`, { "groupName": name })
		.then(() => {
			getFreshData()
			setNewAssetClassName("")
		})
	}

	const addAsset = (assetId, classId) => {
		remote.post(`/crm/groupings/${model.id}/${classId}/${assetId}`, {weighting: 0.00})
		.then(() => {
			getFreshData()
		})
	}

	const removeAsset = (assetId, classId) => {
		remote.delete(`/crm/groupings/${model.id}/${classId}/${assetId}`)
		.then(() => {
			getFreshData()
		})
	}

	const editWeighting = (weighting, classId, assetId) => {
		remote.put(`/crm/groupings/${model.id}/${classId}/${assetId}`, {weighting})
		.then(() => {
			getFreshData()
		})
	}

	const allocationTableColWidths = [400 + "px", 100 + "%"]
	const allocationTableHeaders = ["Name", "Tickers/Published"]
	const allocationTableDataMap = (dataToMap) => {
		return dataToMap.map((assetClass, idx) => {
			const assets = assetClass && assetClass.assets && assetClass.assets.tickers
			const publishedAssets = assets.filter(val => val.published)
			return (
				<tr className='tr-borders'>
					<td className="model-portfolio-class-name" style={{border: "0"}}>
						{
							editingClassName && editingClassName.index === idx
								? <>
									<input className="model-portfolio-class-input" style={{ width: `calc(10px + ${editingClassName.value.length}ch)`, maxWidth: "300px", minWidth: "50px" }} value={editingClassName.value} onChange={(e) => { setEditingClassName({ index: idx, value: e.target.value }) }} />
									<Save className="model-portfolio-class-save-icon" style={{ display: "block" }} onClick={() => {
										setEditingClassName(null)
										updateAssetClassName(editingClassName.value, idx)
									}} />
								</>
								: <>
									<p>{assetClass.groupName}</p>
									<Edit2 className="model-portfolio-class-edit-icon" onClick={() => setEditingClassName({ index: idx, value: assetClass.name })} />
								</>
						}</td>
					<td>{assets.length}/{publishedAssets.length}</td>
				</tr>
			)
		})
	}

	if (noModels) {
		return <Page>
			<PageContentBodyGrid id="model-portfolio-page" rowGap="xl" >
				<PageGridItem container col="1 / span 12">
					No model portfolios created
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	}

	return (
		<Page>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<div className="model-portfolio-banner">
					<h1 className='model-portfolio-banner-title'>
						Asset Classes
					</h1>
					<Plus onClick={() => setShowCreateClassForm(true)} className='model-portfolio-banner-icon' />
				</div>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="model-portfolio-page" rowGap="xl" >
				{/* grid container for the ticker details section */}
				<PageGridItem container col="1 / span 12" style={{marginBottom: "2rem"}}>
					{model && model.groups && <Table
						minWidth={450}
						colWidths={allocationTableColWidths}
						headers={allocationTableHeaders}
						dataMap={allocationTableDataMap(model.groups)}
						dataLoading={loading}
					/>}
					{showCreateClassForm
						? <div style={{marginTop: "2rem"}} >
							<h1 className='model-portfolio-banner-title' style={{ marginBottom: "1rem" }}>New Asset Class</h1>
							<FlexWrapper direction="row" gap="m">
								<PageGridItem container col="1 / span 2">
									<DetailField editMode={true} placeholder="Name" value={newAssetClassName} onChange={(e) => setNewAssetClassName(e.target.value)} />
								</PageGridItem>
								<PageGridItem container col="5 / span 2">
									<FlexWrapper direction="row" gap="m">
										<Divider height={24} dark vertical />
										<Save size={22} color="#0D8EFD" onClick={() => createAssetClass(newAssetClassName)} />
										<X size={22} color="#999DAD" onClick={() => { setShowCreateClassForm(false); setNewAssetClassName("") }} />
									</FlexWrapper>
								</PageGridItem>
							</FlexWrapper>
						</div>
						: <div className="model-portfolio-add-class-row" onClick={() => setShowCreateClassForm(true)}><p>Add Asset Class</p> <Plus className='model-portfolio-banner-icon small' /></div>
					}
				</PageGridItem>
				<PageGridItem container col="1 / span 12">
					{model && model.groups && model.groups.map((assetClass, idx) => {
						return <div key={`${assetClass.name}-${idx}`} style={{ marginTop: "0rem" }}>
							<ModelAssetClassTable assetClass={assetClass} updateAssetClassName={updateAssetClassName} addAsset={addAsset} removeAsset={removeAsset} editWeighting={editWeighting} />
						</div>
					})}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
	);
}

export default ModelPortfolioPage;