import React from 'react';
import "./PopupMenuHeader.scss";

const PopupMenuHeader = (props) => {
	return (
		<div className="popup-menu-header-wrapper">
			{props.header || props.children}
		</div>
	)
}

export default PopupMenuHeader;