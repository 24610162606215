import React from 'react';
import { useState } from 'react';
import { Camera, TrendingUp } from 'react-feather';
import TickerSectorImage from '../../../components/TickerSectorImage';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ImageBrowserModal from "../../../components/popups/ImageBrowserModal";

const SectorPageDetails = ({ editMode, sector }) => {
	const [showBrowserModal, setShowBrowserModal] = useState(false);

	return (
		<>
			{/* Modal for updating image */}
			{showBrowserModal && (
				<ImageBrowserModal
					handleClose={() => setShowBrowserModal(false)}
				/>
			)}
			<PageGridItem col="1 / span 6" justifyContent="between">
				<DetailSectionHeader header="Sector Details" icon={<TrendingUp />} />
				<TickerSectorImage
					size="s"
					hoverUpload
					hoverIcon={<Camera />}
					onClick={() => setShowBrowserModal(true)}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField bold autoFocus editMode={editMode} label="Name" value={sector.name} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField editMode={editMode} label="Region" value={sector.region} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField label="Date Created" value={sector.dateAdded} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField label="Created By" value={sector.createdBy} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField label="Last Published" value={sector.lastPublished} />
			</PageGridItem>
		</>
	);
}

export default SectorPageDetails;