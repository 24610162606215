import React, { useEffect } from 'react';
import { useState } from 'react';
import FlexWrapper from '../../FlexWrapper';
import MapChart from '../Charts/MapChart';
import PercentageBars from '../Charts/PercentageBars';
import { useRemote } from '../../../Utils/Utils';
import { format, startOfMonth, sub } from 'date-fns';


const MapMetrics = ({ timeFrame }) => {
	const [content, setContent] = useState("");
	const [data, setData] = useState([]);

	const remote = useRemote();

	useEffect(() => {
		const now = new Date();
		const then = typeof timeFrame === "string" ? new Date(timeFrame) : startOfMonth(sub(now, timeFrame));
		remote.get(`/analytics/aggregates/sessions?from=${format(then,'yyyy-MM-dd')}&to=${format(now, 'yyyy-MM-dd')}`).then(results => {
			const countryCount = {};
			results.forEach(element => {
				if (element.location) {
					if (countryCount[element.location]) {
						countryCount[element.location]++;
					} else {
						countryCount[element.location] = 1;
					}
				}
				
			});
			console.log("results", results)
			console.log("countryCount", countryCount)
			
			const asArray = Object.keys(countryCount).map((key) => {return {name: key, value: countryCount[key]}});
			console.log("asArray", asArray)
			setData(asArray);
		})
	}, [timeFrame])

	return (
		<>
			<FlexWrapper gap="xxl" align="flex-start" justify="space-between">
				{/* Bars */}
				<div style={{ width: "100%", maxWidth: 350 }}>
					<PercentageBars data={data} />
					<div style={{paddingTop: "1.5rem", fontSize: "0.85rem"}}>{content}</div>
				</div>
				{/* Map Here */}
				<MapChart setTooltipContent={setContent} userCountByCountry={data} />
			</FlexWrapper>
		</>
   );
}

export default MapMetrics;