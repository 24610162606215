import React from 'react';
import CreatableInputField from '../../../components/CreatableInputField';
import SearchField from '../../../components/Input/SearchField';
import TextFieldLabel from '../../../components/Input/TextFieldLabel';

const SelectDetailField = (props) => {
	const { placeholder, label, value, editMode, onChange, autoFocus, disabled, options, creatable } = props;
	return (
		<div className="detail-field-wrapper">
			{editMode ? (
				<React.Fragment>
					<TextFieldLabel>{label}</TextFieldLabel>
					{creatable ? (
						<CreatableInputField
							onInputChange={(e) => console.log(e)}
							autoFocus={autoFocus}
							placeholder={placeholder || label}
							value={value}
							onChange={onChange}
							options={options}
						/>
					) : (
						<SearchField
							disabled={disabled}
							autoFocus={autoFocus}
							placeholder={placeholder || label}
							value={value}
							options={options}
							onChange={onChange}
							className="transition-fade-up-slow"
						/>
					)}
				</React.Fragment>
			) : (
				<React.Fragment>
					<label className="colour-text-label-grey transition-fade-up">{label}</label>
					<p className="mt-s transition-fade-up-slow" style={{ wordBreak: "break-all" }}>
						{value?.label || value || "-"}
					</p>
				</React.Fragment>
			)}
		</div>
	);
}

export default SelectDetailField;