import React, { Component } from "react";
import { withThreeSkyeGlobal } from "@threeskye/global";

export default function (WrappedComponent, onlyTickers) {
	return withThreeSkyeGlobal(
		class extends Component {
			state = { tickerList: null, tickerListLoading: true };
			constructor(props) {
				super(props);
				this.fetchTickerList = this.fetchTickerList.bind(this);
			}

			componentDidMount() {
				this.fetchTickerList();
			}

			fetchTickerList() {
				this.props.onlyTickers 
				? this.props.storage.getOrFetch("/crm/tickers").then((fetchedTickers) => this.setState({ tickerList: fetchedTickers.map(t => t.ric), tickerListLoading: false }))
				: this.props.storage.getOrFetch("/tickers/list").then((fetchedTickers) => this.setState({ tickerList: fetchedTickers, tickerListLoading: false }));
			}

			render() {
				const { tickerList, tickerListLoading } = this.state;
				const { storage, ...otherProps } = this.props;
				return <WrappedComponent {...otherProps} {...{ tickerList, tickerListLoading }} />;
			}
		}
	);
}
