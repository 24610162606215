import React from 'react';
import { Key } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import PageGridItem from '../../Shared/Page/PageGridItem';

const CompanyKeyContact = (props) => {
	const { contact } = props;

	const getPhoneNumber = () => {
		if (contact.phone && contact.phone.startsWith("{")) {
			let parsedNumber = JSON.parse(contact.phone)
			return parsedNumber.idd + " " + parsedNumber.number
		}
		else return ""
	}

	return (
		<React.Fragment>
			<PageGridDivider col="1 / span 6" />
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Key Contact" icon={<Key />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					label="First Name"
					value={contact.firstName}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					label="Last Name"
					value={contact.lastName}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					label="Title"
					value={contact.role}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					label="Email"
					value={contact.email}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					label="Phone"
					value={getPhoneNumber()}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					label="Office"
					value={contact.location}
				/>
			</PageGridItem>
		</React.Fragment>
	);
}

export default CompanyKeyContact;