import React, { useState } from 'react';
import withRouteChange from "@threeskye/route-change"
import MiniUsersList from '../../Shared/TablesAndLists/MiniUsersList';
import { useParams } from 'react-router';
import { useRemote } from '../../../Utils/Utils';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';


const CompanyUsersList = ({ editMode, contacts, setContacts, company }) => {

	const [displayModal, setDisplayModal] = useState([])
	const { companyId } = useParams();

	const remote = useRemote()

	const addUser = (user) => {
		remote.put(`/crm/organisations/${company.id}/contacts/${user.value}`)
			.then((response) => {
				toastSuccess('User Added')
				setContacts([...contacts, user.userDTO])
			})
			.catch(() => toastDanger('User could not be added'))
	}

	const removeUser = (user) => {
		remote.delete(`/crm/organisations/${company.id}/contacts/${user.id}`)
			.then((response) => {
				setDisplayModal([false, toastSuccess("User removed")])
				let newArray = []
				contacts.forEach((contact) => {
					
					if (contact.id !== user.id) {
						newArray.push(contact)
					}
				})
				setContacts(newArray)
			})
			.catch(() => toastDanger('User could not be removed'))
	}

	return <MiniUsersList
		data={contacts}
		editMode={editMode}
		companyId={companyId}
		setData={setContacts}
		addUser={addUser}
		removeUser={removeUser}
		displayModal={displayModal}
	/>;
}

export default withRouteChange(CompanyUsersList);