import React from "react";
import "./SuggestionMessage.scss";

const SuggestionMessage = ({message, children, className}) => {
	return (
		<div className={`suggestion-message${className ? ' ' + className : ''}`}>
			<p>{message || children}</p>
		</div>
	);
}

export default SuggestionMessage;