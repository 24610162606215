import React, { useEffect, useState } from 'react';
import { Eye } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import ProfileImage from '../../../components/ProfileImage';
import withRouteChange from "@threeskye/route-change"
import { useRemote, useStorage } from '../../../Utils/Utils';
import { format, sub } from 'date-fns';

const TopAnalystsTable = ({dateRange, changeRoute}) => {
	const remote = useRemote();
	const storage = useStorage();
	const [authorData, setAuthorData] = useState([])
	const [analysts, setAnalysts] = useState([])

	const end = format(new Date(), 'yyyy-MM-dd')

	useEffect(() => {
		storage.getOrFetch(`/portal/analysts`).then(setAnalysts);
	}, [])

	useEffect(() => {
		let time = { months: 6 }

		if (dateRange) {
			time = dateRange
		}
		let date = typeof dateRange === "string" ? dateRange : format(sub(new Date(), time), "yyyy-MM-dd")
		remote.get(`/analytics/aggregates/documents?from=${date}&to=${end}`).then((stats) => {
			const authorAggregate = {};

			stats.forEach(r => {
				const authorStats = authorAggregate[r.publishedBy] || { author: r.publishedBy, count: 0, minutes: 0, uniqueViews: 0 };
				authorStats.uniqueViews += r.uniqueViews
				authorAggregate[r.publishedBy] = authorStats;
	
			})
			const authorAsArray = Object.keys(authorAggregate).map(key => authorAggregate[key])

			const topFiveDescendingSort = authorAsArray.sort((a, b) => b.uniqueViews - a.uniqueViews).slice(0,5)
	
			setAuthorData(topFiveDescendingSort);
		});
	}, [dateRange]);




	return <AuthorTable data={authorData} changeRoute={changeRoute} authors={analysts} />

}

const getInitials = (name) => {
	const arr = name && name.split(" ") || []
	let initials = arr.map(word => word.charAt(0))
	return initials.join("")
}

const AuthorTable = ({ data, authors, changeRoute }) => {
	const colWidths = ["52px", "auto", "150px"];

	const dataMap = () => data && data.map((research, idx) => {
		const relevantAnalyst = authors && authors.find(a => a.name === research.author);

		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(`/analytics/authors/author/${relevantAnalyst.email}`)}>
				<td>
					<ProfileImage user={relevantAnalyst} initials={getInitials(research.author)} src={relevantAnalyst && relevantAnalyst.photo ? "data:image/png;base64, " + relevantAnalyst.photo : null} size={24} />
				</td>
				<td className="truncate">{research.author}</td>
				<td className="table-action-icons transition-fade-in-slow">
					<div className='top-research-icons  top-authors-viewed-icons'>
						<IconCounter icon={<Eye />} tooltip="Views" dataAvailable count={research.uniqueViews} />
					</div>
				</td>
			</tr>
		);
	});

	return <Table rowSize="s" colWidths={colWidths} dataMap={dataMap()} />

}

export default withRouteChange(TopAnalystsTable);