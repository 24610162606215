import React from 'react';
import { useState } from 'react';
import SuggestionMessage from '../EmptyMessage/SuggestionMessage';
import TickerSectorImage from '../TickerSectorImage';
import "./ImageGallerySection.scss"

const ImageGallerySection = ({ header, images, onImageSelect, size }) => {
	const [selectedImage, setSelectedImage] = useState("");

	return (
		<div className="image-gallery-section">
			{/* <div className="gallery-header mb-m">{header}</div> */}
			{images && images.length > 0 ? (
				<div className="gallery-images">
					{images &&
						images.map((image, idx) => (
							<TickerSectorImage
								onClick={() => {onImageSelect && onImageSelect(image); setSelectedImage(idx)}}
								selected={selectedImage === idx}
								key={idx}
								image={image}
								size={size ? size : "xl"}
								className="cursor-pointer"
								galleryImage
							/>
						))}
				</div>
			) : (
					<SuggestionMessage message={<>No images added yet. <span className="text-link">Try adding one now.</span></>}
				/>
			)}
		</div>
	);
}
export default ImageGallerySection;