import React from 'react';
import { Briefcase } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import TextFieldLabel from '../../../components/Input/TextFieldLabel';
import Button from '../../../components/Buttons/Button';
import CreatableInputField from '../../../components/CreatableInputField';
import useWindowDimensions from '../../../Utils/Utils';

const UserCompanyDetails = ({ editMode, newUser, company, blankCompany, allCompanies, setCompany, setCreateNewCompanyMode, createNewCompanyMode, resetCompany, locations, createCompany }) => {

	let mappedCompanies = allCompanies.map((option) => { return { value: option, label: option.name } })
	const { width } = useWindowDimensions();

	return (
		<React.Fragment>
			<PageGridItem col="1 / span 6" justifyContent="between">
				<DetailSectionHeader header="Company Details" icon={<Briefcase />} />
				{createNewCompanyMode &&
					<div className="flex-justify-center">
						<Button className="mr-m" onClick={() => { createCompany() }} >Create</Button>
						<Button variant="secondary" onClick={() => { resetCompany() }} >Cancel</Button>
					</div>
				}
			</PageGridItem>
			{(createNewCompanyMode || !editMode)
				? <PageGridItem col="1 / span 3">
					<DetailField
						editMode={editMode}
						placeholder="Name"
						label="Name"
						value={company.name && company.name}
						onChange={e => {
							setCompany({ ...company, name: e.target.value })
						}}
					/>
				</PageGridItem>
				: <PageGridItem col={newUser ? "1 / span 6" : "1 / span 3"}>
					<TextFieldLabel label="Name" />
					<CreatableInputField
						isMulti={false}
						options={[...mappedCompanies]}
						onChange={(newCompany) => {
							if (newCompany.__isNew__) {
								setCreateNewCompanyMode(true)
								setCompany({ ...blankCompany, name: newCompany.value })
							}
							else setCompany(newCompany.value);
						}}
						placeholder="Name"
						menuPosition="fixed"
						onInputChange={() => null}
						onKeyDown={() => null}
						values={{ label: company.name, value: company.name }}
					/>
				</PageGridItem>
			}
			{
				(!newUser || createNewCompanyMode) && (
					<>
						<PageGridItem col="4 / span 3">
							<DetailField
								editMode={createNewCompanyMode}
								label="Website"
								value={company.website && company.website}
								onChange={e => setCompany({ ...company, website: e.target.value })}
							/>
						</PageGridItem>
						<PageGridItem col="1 / span 3">
							<DetailField
								editMode={createNewCompanyMode}
								label="Phone"
								value={company.phone && company.phone}
								onChange={e => setCompany({ ...company, phone: e.target.value })}
							/>
						</PageGridItem>
						<PageGridItem col="4 / span 3">
							<DetailField
								editMode={createNewCompanyMode}
								label="Headquarters"
								value={company.locations && company.locations.location && company.locations.location}
								onChange={e => setCompany({ ...company, locations: { value: e.target.value, label: e.target.value, location: e.target.value } })}
							/>
						</PageGridItem>
					</>
				)
			}
			{/* {width > 1200 && <PageGridItem col="1 / span 6"><div style={{marginBottom: editMode ? 232 : 168}} /></PageGridItem>} */}
		</React.Fragment >
	);
}

export default UserCompanyDetails;