import {Component} from 'react';
import { withRouter } from 'react-router-dom';
class Authorise extends Component {

	componentDidMount() {
		const token = this.props.match.params.token;

		window.sessionStorage.setItem("3skye.auth.token", token);
		this.props.history.push("/");
	}

	render() {
		return "";
	}
}

export default withRouter(Authorise);