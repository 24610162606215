import React from 'react';
import placeholder from "../images/sectors/Research 2.png";
import FlexWrapper from '../layouts/FlexWrapper';
import TextInput from './Input/TextInput';
import ExtensionLabel from './Labels/ExtensionLabel';
import Tooltip from './popups/Tooltip';
import TickerSectorImage from './TickerSectorImage';
import "./TickerSectorItem.scss";

const TickerSectorItem = ({ label, extensionLabel, extensionTooltip, size, imageSize, image, tooltip, hoverUpload, hoverIcon, imageOnClick, editMode, labelPlaceholder, subLabelPlaceholder, country, filename, labelSize, onClick }) => {
	
	return (
		<Tooltip className={`ticker-sector-tooltip${size ? " tsi-size-" + size : ""}`} disabled={!tooltip} label={tooltip}>
			<div className={`ticker-sector-item${size ? " tsi-size-" + size : ""}`} onClick={onClick}>
				{/* image/logo */}
				<TickerSectorImage hoverUpload={hoverUpload} hoverIcon={hoverIcon} image={image ? image : placeholder} size={size} imageSize={imageSize} onClick={imageOnClick} country={country} filename={filename} />
				<div className="tsi-details">
					{editMode ? (
						<FlexWrapper fullWidth direction="column" gap="s" className="transition-fade-up">
							<TextInput autoFocus fullWidth size="small" placeHolder={labelPlaceholder} value={label} />
							<TextInput fullWidth size="small" placeHolder={subLabelPlaceholder} value={extensionLabel} />
						</FlexWrapper>
					) : (
						<>
							<div className={`tsi-label ${labelSize ? `label-size-${labelSize}` : ""}`} >{label}</div>
							{extensionLabel && !extensionTooltip ? (
								<ExtensionLabel className="tsi-sub-label">{extensionLabel}</ExtensionLabel>
							) : (
								extensionLabel &&
								extensionTooltip && (
									<Tooltip label={extensionTooltip}>
										<ExtensionLabel className="tsi-sub-label">{extensionLabel}</ExtensionLabel>
									</Tooltip>
								)
							)}
						</>
					)}
				</div>
			</div>
		</Tooltip>
	);
}

export default TickerSectorItem;