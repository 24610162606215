import React, { useState } from 'react';
import Checkbox from '../../Checkbox';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import { withData } from "../../../DataController";
import TextInput from '../../Input/TextInput';
import TextFieldLabel from '../../Input/TextFieldLabel';
import Button from '../../Buttons/Button';
import { toast, toastDanger, toastSuccess } from '../../popups/Toast';

const OptionsSidebarExtension = ({slideIn, slideOut, template, optionalPages, setOptionalPages, loadingLocalData, data }) => {
	// const { slideIn, slideOut } = props;

	const [numRows, setNumRows] = useState([]);
	const [numCols, setNumCols] = useState([]);

	const includePage = (page) => {
		const index = optionalPages.indexOf(page);
		if (index > -1) {
			optionalPages.splice(index, 1);
		} else {
			optionalPages.push(page);
		}
		setOptionalPages(optionalPages);
		data.updateLocalData("OPTIONAL_PAGES", JSON.stringify(optionalPages), "SIDEBAR");
	}

	const setClipboard = (text) => {
		const type = "text/html";
		const blob = new Blob([text], { type });
		const data = [new window.ClipboardItem({ [type]: blob })];
	
		navigator.clipboard.write(data).then(
			() => { toastSuccess("Table copied, please paste into the editor") },
			() => { toastDanger("Copy failed, you might need to give permission to your browser") } 
			);
	}

	const createTable = () => {
		if (!window.ClipboardItem) {
			toastDanger("Unfortunately your browser does not support copying to the clipboard");
			return;
		}
		let string = "<table>";
		for (let r = 0; r < (numRows || 2); r++) {
			let row = "<tr>";
			for (let c = 0; c < (numCols || 2); c++) {
				row += "<td width='10'></td>";
			}
			row += "</tr>";
			string += row;
		}
		string += "</table>";

		setClipboard(string);
	}

	return (
		<SidebarExtensionWrapper noPadding position="right" shadow slideIn={slideIn} slideOut={slideOut}>
			<SideBarSection header="Options">
				{template && template.optionalPages && template.pages
					.filter((page) => page.optional)
					.map((page, idx) => {
						return (
							<Checkbox
								key={idx}
								label={"Include " + page.name}
								checked={optionalPages && optionalPages.includes(page.name)}
								onChange={() => includePage(page.name)}
								disabled={loadingLocalData}
							></Checkbox>
						);
					})}
				{/* <Checkbox label={"Include Numbers Page"}></Checkbox> */}
				<hr/>
				<div>
					<h2>Insert table</h2>
					<div style={{paddingBottom:'5px'}}>
					<TextFieldLabel className="colour-text-label-grey transition-fade-up">Number of Rows</TextFieldLabel>
					<TextInput onChange={(e) => setNumRows(e.target.value)} size="small" placeHolder={"Num Rows"} value={numRows} />
					</div>
					<div style={{paddingBottom:'5px'}}>
					<TextFieldLabel className="colour-text-label-grey transition-fade-up">Number of Columns</TextFieldLabel>
					<TextInput onChange={(e) => setNumCols(e.target.value)} size="small" placeHolder={"Num Cols"} value={numCols} />
					</div>
					<Button classNames="back-to-home-button" rounded onClick={() => createTable()}>
						Copy table to clipboard
					</Button>
				</div>
			</SideBarSection>
		</SidebarExtensionWrapper>
	);
}

export default withData(OptionsSidebarExtension);