import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Eye } from 'react-feather';
import TableActionIcon from '../../components/Table/TableActionIcon';
import TickerSectorItem from '../../components/TickerSectorItem';
import PdfIcon from '../../images/icons/PdfIcon';
import XlsIcon from '../../images/icons/XlsIcon';
import PublicationSettings from './PublicationManagement/PublicationSettings';
import Tooltip from '../../components/popups/Tooltip';

const LibraryTableRow = ({ data, idx, tickers, expandedRows, setExpandedRows, getImage, onCraigsDocClick, onDeliveryClick, models, modelsLoading, showPortalInfo , filterOnclick}) => {
	const [document, setDocument] = useState({})
	const [imageKey, setImageKey] = useState(Date.now())

	const updateVisibility = (bool) => {
		let newDocument = { ...document, publishedToPortal: bool }
		setDocument(newDocument)
	}

	const updateImageKey = () => {
		setImageKey(Date.now())
	}

	useEffect(() => {
		setDocument(data)
	}, [data])

	const delivered = document.delivered;
	const sent = document.sent;
	const pdf = document.downloads;
	const model = document.modelDownloads;
	const email = document.opened;
	const portal = document.views;
	const failed = delivered > sent ? 0 : (sent - delivered);
	const datafiles = (document && document.files && document.files.filter(f => f.data)) || [];
	const defaultPdf = (document && document.files && document.files.filter(f => f.defaultPdf)) || [];
	const ticker = tickers && tickers.find(t => t.ric === document.ticker)

	return (<>
		<tr key={idx} className={expandedRows && expandedRows.includes(document.docId) ? "library-row-selected" : ""} >
			<td  onClick={(e) => onDeliveryClick(e, document)} style={{cursor: filterOnclick ? "default" : "pointer"}}>
				<p>{document.date && format(parseISO(document.date), 'dd/MM/yyyy')}</p>
			</td>
			{showPortalInfo &&
				<td className="pl-m">
					<Tooltip label="Portal Info">
						<Eye
							className={`published-icon ${document.publishedToPortal ? "active" : ""}`}
							onClick={() => {
								expandedRows && expandedRows.includes(document.docId)
									? setExpandedRows(expandedRows.filter((val) => val !== document.docId))
									: setExpandedRows([...expandedRows, document.docId])
								}} />
					</Tooltip>
				</td>
			}
			<td  onClick={(e) => onDeliveryClick(e, document)} style={{cursor: filterOnclick ? "default" : "pointer"}}>
				<TickerSectorItem label={document.ticker} image={`/api/public/images/note/${document.docId}.png?${imageKey}`} country={ticker?.country} filename={ticker?.ric} />
			</td>
			<td  onClick={(e) => onDeliveryClick(e, document)} className="research-title" style={{cursor: filterOnclick ? "default" : "pointer"}}>
				<p>{document.title}</p>
			</td>
			<td  onClick={(e) => onDeliveryClick(e, document)} style={{cursor: filterOnclick ? "default" : "pointer"}}>
				<p>{document.author}</p>
			</td>
			<td className="" >
				<TableActionIcon onClick={() => onCraigsDocClick(defaultPdf[0])} icon={<PdfIcon size="16px" />} toolTip="PDF download" />
				<TableActionIcon
					disabled={datafiles.length === 0}
					onClick={(event) => {
						event.stopPropagation()
						datafiles.forEach(onCraigsDocClick)
					}}
					icon={<XlsIcon size="16px" />}
					toolTip={datafiles.length === 0 ? "No data" : "Data download (" + datafiles.length + ")"}
				/>
			</td>
			<td className="pointer" onClick={(e) => filterOnclick ? filterOnclick("delivered") : onDeliveryClick(e, document)}>
				{delivered}
			</td>
			<td className="pointer" onClick={(e) => filterOnclick ? filterOnclick("failed") : onDeliveryClick(e, document)}>
				{failed}
			</td>
			<td className="pointer" onClick={(e) => filterOnclick ? filterOnclick("read") : onDeliveryClick(e, document)}>{email}

			</td>
			<td style={{cursor: filterOnclick ? "default" : "pointer"}} onClick={(e) => onDeliveryClick(e, document)}>{portal}
			</td>
			<td style={{cursor: filterOnclick ? "default" : "pointer"}} onClick={(e) => onDeliveryClick(e, document)}>
				<div className="icon-counter-count">{pdf}</div>
			</td>
			<td style={{cursor: filterOnclick ? "default" : "pointer"}} onClick={(e) => onDeliveryClick(e, document)}>
				<div className="icon-counter-count">{model}</div>
			</td>
		</tr>
		{!modelsLoading && expandedRows && expandedRows.includes(document.docId) &&
			<tr className="no-hover library-row-expanded" key={"portal-details-" + document.docId}>
				<td colSpan={12}>
					<PublicationSettings data={document} setData={(val) => setDocument(val)} models={models} updateVisibility={updateVisibility} updateImageKey={updateImageKey} modelsLoading={modelsLoading} />
				</td>
			</tr>
		}
	</>
	);
}

export default LibraryTableRow;