import React from "react";
import "../../../layouts/Shared/Banner/TopBannerExtension.scss";

const SideBarExtensionHeader = (props) => {
    const { icon, header, noMargin, border, style} = props;
    return (
        <div className={`top-banner-ext-header short-banner no-tabs${border === "bottom" ? " border-bottom" : ""} ${noMargin ? "no-margin" : ""}`} style={style}>
            {icon}
            <h3>{header}</h3>
        </div>
    );
}

export default SideBarExtensionHeader;