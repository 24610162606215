import React, { Component } from "react";
import "./AdminPage.scss";
import { Route, Switch } from "react-router";
import AdminCompanyDisclaimersPage from "./AdminCompanyDisclaimersPage/AdminCompanyDisclaimersPage";
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import withRouteChange from "@threeskye/route-change";
import RolePermissionsList from "./RolePermissions/RolePermissionsList";
import UserManagement from "./UserManagement/UserManagement";
import SystemUserPage from "./UserManagement/SystemUserPage";
import TickerList from "./Ticker/TickerList";
import TickerPage from "./Ticker/TickerPage";
import TemplateList from "./Templates/TemplateList";
import TemplateMgmt from "./Templates/TemplateMgmt";
import SectorList from "./Sectors/SectorList";
import SectorPage from "./Sectors/SectorPage";
import CompanyMgmt from "./System/CompanyMgmt";
import ModelPortfolioPage from "./ModelPortfolio/ModelPortfolioPage";
import NoAccess from "../../NoAccess";
import { hasFeature } from "../../Utils/Utils";

export const ADMIN_SUB_PAGES = { TICKERS: "tickers", DISCLAIMERS: "disclaimers", MAILING_LIST: "mailing-list", REVIEW_LIST: "review-list" };
export const ADMIN_SUB_PATHS = { [ADMIN_SUB_PAGES.DISCLAIMERS]: "/disclaimers", [ADMIN_SUB_PAGES.MAILING_LIST]: "/mailing-list", [ADMIN_SUB_PAGES.REVIEW_LIST]: "/review-list" };

class AdminPage extends Component {
	constructor(props) {
		super(props);
		this.getMenuItemProps = this.getMenuItemProps.bind(this);
	}

	getMenuItemProps(adminSubPage, notExact) {
		const { changeRoute } = this.props;
		const fullPath = [ROUTE_PATHS[PAGES.ADMIN], ADMIN_SUB_PATHS[adminSubPage]].filter((p) => !!p).join("");
		const active = notExact ? this.props.location.pathname.startsWith(fullPath) : this.props.location.pathname === fullPath;
		const className = active ? "active" : null;
		const onClick = () => changeRoute(fullPath);
		return { className, onClick };
	}
	
	render() {
		const me = this.props.me;
		if (!me || !me.perms) {
			return <NoAccess/>
		}
		return (
			<>
				<Switch>
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/tickers"} component={hasFeature("TICKERS", TickerList, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/tickers/:ticker"} component={hasFeature("TICKERS", TickerPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/model-portfolio"} component={hasFeature("MODEL_PORTFOLIOS", ModelPortfolioPage, me)} />
					{/* <Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/groupings"} component={SectorList} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/groupings/:grouping"} component={SectorList} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/groupings/:grouping"} component={SectorList} /> */}
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/sectors"} component={hasFeature("SECTORS", SectorList, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/sectors/sector"} component={hasFeature("SECTORS", SectorPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + ADMIN_SUB_PATHS[ADMIN_SUB_PAGES.DISCLAIMERS]} component={hasFeature("DISCLAIMER", AdminCompanyDisclaimersPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/user-management"} component={() => hasFeature("USER_MANAGEMENT", <UserManagement me={me}/>, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/user-management/:userId"} component={hasFeature("USER_MANAGEMENT", SystemUserPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/company-management"} component={hasFeature("COMPANY_MANAGEMENT", CompanyMgmt, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/role-permissions"} component={hasFeature("ROLES", RolePermissionsList, me)} />
					<Route path={ROUTE_PATHS[PAGES.ADMIN] + "/templates/:template"} component={hasFeature("TEMPLATES", TemplateMgmt, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/templates"} component={hasFeature("TEMPLATES", TemplateList, me)} />
				</Switch>
			</>
		);
	}
}

export default withRouteChange(AdminPage);
