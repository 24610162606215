import React from 'react';
import { withData } from '../DataController';

class Slider extends React.Component {


	constructor(props) {
		super(props);
	
		this.state = {
			value: ""
		};

		this.listener = this.listener.bind(this);
	}

	componentDidMount() {
		this.props.data.addListener(this.listener);
		this.listener();
	}

	componentWillUnmount() {
		this.props.data.removeListener(this.listener);
	}

	listener() {
		this.setState({value: this.props.data.getValue(this.props.config.value)});
	}

	render() {
		const config = this.props.config;
		const styles = this.props.styles;
		
		let valueAsFloat = parseFloat(this.state.value) - config.min;
		if (config.inverted) {
			valueAsFloat = (config.max - config.min) - valueAsFloat;
		}

		const segmentSize = Math.abs(config.min - config.max) / config.segments.length;

		const selectedSegment = (valueAsFloat) / segmentSize;

		const arrowPosition = (config.width / Math.abs(config.min - config.max)) * valueAsFloat;

/* 		private Float width;
		private Float height;
		private List<String> segments;
		private List<String> labels;
		private String labelStyle;
		private String labelStyleActive;
		private Float min;
		private Float max;
		private String value;
		private Boolean inverted;
 */	

		const labelStyle = this.props.data.getStyle(styles[config.labelStyle]);
		const activeStyle = this.props.data.getStyle(styles[config.labelStyleActive]);

		const bits = [];
		for (let i = 0; i < config.segments.length; i++) {
			const style = {
				width: (config.width / config.segments.length) + "pt",
				height: config.height + "pt",
				backgroundColor: config.segments[i],
				left: ((config.width / config.segments.length) * i) + 'pt',
				position: "absolute"
			}
			if (i === 0) {
				style.borderRadius = config.height+"pt 0 0 "+config.height+"pt";
			} else if (i === config.segments.length - 1) {
				style.borderRadius = "0 "+config.height+"pt "+config.height+"pt 0";
			}
			bits.push(<div style={style}></div>);

			const labelStyleAug = (Math.trunc(selectedSegment) === i) ? {...activeStyle} : {...labelStyle};
			labelStyleAug.position = 'absolute';
			labelStyleAug.top = (- config.height - parseFloat(labelStyleAug.fontSize)) + "pt";
			labelStyleAug.width = (config.width / config.segments.length) + "pt"
			labelStyleAug.textAlign = "center";
			labelStyleAug.left = ((config.width / config.segments.length) * i) + 'pt';
			
			bits.push(<div style={labelStyleAug}>{config.labels[i]}</div>)
		}
		//arrow
		const arrowStyle = {
			position: 'absolute',
			left: (arrowPosition-1)+'pt',
			width: 0,
			height: 0,
			top: ((config.height / 2) - 2) + 'pt',
			borderLeft: '6px solid transparent',
			borderRight: '6px solid transparent', 	 
			borderBottom: '12px solid white'
		}
		bits.push(<div style={arrowStyle}></div>)

		const arrowOverlayStyle = {
			position: 'absolute',
			left: arrowPosition+'pt',
			width: 0,
			height: 0,
			top: (config.height / 2) + 'pt',
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent', 	 
			borderBottom: '10px solid black'
		}
		bits.push(<div style={arrowOverlayStyle}></div>)
		return <>{bits}</>
	}
}

export default withData(Slider);