import React from 'react';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';

const SettingsGeneralPage = () => {
	return (
		<Page noBanner>
			<PageContentBodyGrid rowGap="xl">
				<PageGridItem col="1 / span 8">
					<SuggestionMessage>Settings coming soon...</SuggestionMessage>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default SettingsGeneralPage;