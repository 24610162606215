import React from 'react';
import "./Tab.scss"
const Tab = ({text, active, onClick, disabled}) => {
	return ( 
		<div onClick={!disabled && onClick && onClick} className={`tab ${active ? "active-tab" : ""} ${disabled ? "disabled" : ""}`} >
			<p>
				{text}
			</p>
		</div>
	 );
}
 
export default Tab;