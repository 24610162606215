import React from "react";
import { X } from "react-feather";
import Select, { components } from 'react-select';
import TagToken from "../TagToken";
import "./SearchField.scss";

const MultiValueContainer = (props: MultiValueGenericProps) => {
	return (
		<TagToken>
			<components.MultiValueContainer {...props} />
		</TagToken>
	);
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
	return (
		<components.MultiValueRemove {...props}>
		  <X />
		</components.MultiValueRemove>
	);
  };

const SelectTagField = ({ components, classNames, autoFocus, icon, size, minWidth, width, options, isMulti, ...otherProps }) => {
	const mainClassName = "search-select-field";
	let className = [mainClassName, classNames].filter((cn) => !!cn).join(" ");

	if (size) {
		className += " search-field-size-" + size;
	}
	if (width) {
		className += " search-field-width-" + width;
	}
	if (minWidth) {
		className += " search-field-min-width";
	}

	return <Select
		{...otherProps}
		components={{ ...components, MultiValueContainer, MultiValueRemove, DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => <X /> }}
		isMulti
		isClearable
		cacheOptions
		className={className}
		classNamePrefix={mainClassName}
		autoFocus={autoFocus}
		options={options}
		menuPosition="fixed"
	/>;
}

export default SelectTagField;
