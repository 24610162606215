import React from 'react';

const AnalyticsCounter = ({ label, count, labelStyle, valueStyle, labelClass, valueClass }) => {
	return (
		<div className="analytics-counter">
			<p style={labelStyle} className={`font-size-body-s mb-xs${labelClass}`}>
				{label}
			</p>
			<h1 style={valueStyle} className={valueClass ? valueClass : ""}>{count}</h1>
		</div>
	);
};

export default AnalyticsCounter;