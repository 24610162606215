import React from 'react';
import DropDown from '../../../components/DropDown';

const DateRangeToggle = ({value, onChange}) => {

	return (
		<DropDown fixed value={value} transparent clickable>
			<li onClick={() => onChange({months: 6})}>6 Months</li>
			<li onClick={() => onChange({months: 12})}>12 Months</li>
			<li onClick={() => onChange("2020-01-01")}>All Time</li>
		</DropDown>
	);
}

export default DateRangeToggle;