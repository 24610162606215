import React, { Component } from "react";
import { Check } from "react-feather";
import "./Checkbox.scss";
import LoadingIcon from "./LoadingIcon";
import Tooltip from "./popups/Tooltip";

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		if (this.props.onChange && !this.props.disabled && !this.props.loading) {
			this.props.onChange({
				currentTarget: {
					name: this.props.name,
					checked: !this.props.checked,
					value: this.props.value,
					type: "checkbox",
				},
			});
		}
	}

	render() {
		const { label, checked, loading, className, disabled, tooltip } = this.props;
		return (
			<Tooltip label={tooltip} disabled={!tooltip}>
				<div className={`checkbox-field-wrapper${className ? ' ' + className : ''}${disabled ? ' checkbox-disabled' : ''}${checked ? " checkbox-field-wrapper-checked" : ""}`} onClick={this.onClick}>
					{loading ? (
						<LoadingIcon size="16" />
					) : (
						<div className="checkbox-field-box">
							<Check />
						</div>
					)}
					{label}
				</div>
			</Tooltip>
		);
	}
}

export default Checkbox;