import React, { Component } from 'react';
import "./Button.scss";

class Button extends Component {
	render() { 
		const { children, noHover, icon, variant, onClick, loading, className, disabled, fullWidth, buttonRef, small, primary, iconOnly, hover, style } = this.props;

		let buttonClass = "button-base";
		let buttonLoading = "";

		if (className) {
			buttonClass += " " + className;
		}
		if (fullWidth) {
			buttonClass += " button-full-width";
		}
		if (noHover) {
			buttonClass += " button-no-hover";
		}
		if (variant) {
			buttonClass += " button-variant-" + variant;
		} else {
			buttonClass += " button-default";
		}
		if (variant === "outlined-light") {
			buttonClass += " button-variant-outlined button-variant-outlined-light"
		}
		if (variant === "transparent-light") {
			buttonClass += " button-variant-transparent button-variant-transparent-light"
		}
		if (variant === "warning") {
			buttonClass += " button-variant-warning"
		}
		if (variant === "positive") {
			buttonClass += " button-variant-positive"
		}
		if (variant === "danger") {
			buttonClass += " button-variant-danger"
		}
		if (hover === "danger") {
			buttonClass += " button-hover-danger"
		}
		if (hover === "danger-dark") {
			buttonClass += " button-hover-danger-dark"
		}
		if (hover === "transparent") {
			buttonClass += " button-hover-transparent"
		}
		if (hover === "default") {
			buttonClass += " button-hover-default"
		}
		if (disabled) {
			buttonClass += " button-disabled";
		}
		if (primary) {
			buttonClass += " primary";
		}
		if (icon) {
			buttonClass += " button-with-icon";
		}
		if (small) {
			buttonClass += " button-size-small";
		}
		if (iconOnly || !children) {
			buttonClass += " button-icon-only";
		}
		if (loading) {
			buttonClass += " button-loading";
			buttonLoading = (
				'loading'
			);
		}
		if(!children) {
			buttonClass += " button-no-text";
		}
		return (
			<button className={buttonClass} onClick={onClick} ref={buttonRef} style={style}>
				{icon && !loading && icon}
				{buttonLoading}
				{children}
			</button>
		);
	}
}

export default Button;