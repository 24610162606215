import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import { useParams } from 'react-router-dom';
import TemplateGeneralDetails from './TemplateGeneralDetails';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import TemplatePublishActions from './TemplatePublishActions';
import MiniSectorsList from '../../Shared/TablesAndLists/MiniSectorsList';
import TemplateCustomPublishActions from './TemplateCustomPublishActions';
import withRouteChange from "@threeskye/route-change";

const TemplateMgmt = ({changeRoute}) => {
	const { width } = useWindowDimensions();

	const [template, setTemplate] = useState({});
	const [publishActions, setPublishActions] = useState([]);
	const [selectedPublishActions, setSelectedPublishActions] = useState([]);
	const [templateDef, setTemplateDef] = useState({});
	const [notFound, setNotFound] = useState(false);

	const remote = useRemote();

	const { template: templateId } = useParams()

	useEffect(() => {
		remote.get("/crm/templates/"+templateId).then(setTemplate).catch(e => {
			console.log("Caught e ", e);
			setNotFound(true);
		});
		remote.get("/crm/publish-steps").then(setPublishActions);
		remote.get(`/crm/templates/${templateId}/publish-steps`).then(setSelectedPublishActions); 

	}, []);

	useEffect(() => {
		if (template && template.template) {
			try {
			setTemplateDef(JSON.parse(template.template))
			} catch (e) {
				console.log(e)
			}
		}
	}, [template])

	// const updateTemplate = () => {
	// 	remote.post(`/data/template/${template.name}`, template)
	// }

	console.log("Selected publish actions are ", selectedPublishActions);
	const selectedPublishActionIds = selectedPublishActions.map(a => a.stepId);
	const availableActions = publishActions.filter(a => !selectedPublishActionIds.includes(a.id))

	// const colWidths = [30 + "%", 50 + "%", 20 + "%"];
	// const headers = ["Action", "Description", ""];
	
	const addActionToTemplate = (action) => {
		remote.put(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	const removeActionFromTemplate = (action) => {
		remote.delete(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	const goBack = () => {
		changeRoute("/admin/templates")
	}

	// const dataMap = () => availableActions && availableActions.map((action, idx) => {
	// 	return (
	// 		<tr key={action.id} className="cursor-pointer" onClick={() => console.log("Select this action")}>
	// 			<td>{action.displayName}</td>
	// 			<td>{action.description}</td>
	// 			<td className="table-action-icons">
	// 				<TableActionIcon icon={<Plus />} toolTip="Add this action" onClick={() => addActionToTemplate(action)} />
	// 			</td>
	// 		</tr>
	// 	);
	// })

	// const showParams = (action) => {
	// 	return (<>
	// 		{action.params.map((param,idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions}/>)
	// 	}</>)
	// }

/* 	const selectedActionsMap =  selectedPublishActions.map((action, idx) => {
		const actualAction = publishActions.find(p => p.id === action.stepId)
		console.log("(action.params.length !== Object.keys(action.paramValues).length",(action.params.length !== Object.keys(action.paramValues).length));
		console.log(Object.values(action.paramValues));
		console.log(Object.values(action.paramValues).filter(e => e.length === 0));
		console.log("Object.entries(action.paramValues).filter(e => e.length === 0).length > 0",Object.values(action.paramValues).filter(e => e.length === 0).length > 0);
		return (
			<ExpandableTableRow key={idx} expandContent={showParams(action)} className={(action.params.length !== Object.keys(action.paramValues).length || Object.values(action.paramValues).filter(e => e.length === 0).length > 0) ? "invalid" : ""}>
				<td>{actualAction.displayName}</td>
				<td>{actualAction.description}</td>
				<td className="table-action-icons">
					<TableActionIcon icon={<Trash2 />} toolTip="Delete" onClick={() => alert("Delet this")} />
				</td>
			</ExpandableTableRow>
		)
	}); */

	// const hasGroupings = () => {
	// 	console.log("Has groupings is returning ", templateDef, templateDef.ticker, templateDef && templateDef.ticker.type === 'selection')
	// 	return templateDef && templateDef.ticker && templateDef.ticker.type === 'selection';
	// }

	// const groupings = () => {
	// 	const selection = templateDef.ticker;
	// 	 return <PageGridItem container col="1 / span 6">
	// 	 <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
	// 		 {/* General Details */}
	// 		 <PageGridItem col="1 / span 6">
	// 			 <DetailSectionHeader header={"Ticker Selection: "+ selection.selectionName} icon={<FileText />} /> [TODO this should be editable!]
	// 		 </PageGridItem>
	// 		 {selection.options.map((option, idx) => {
	// 			 return (
	// 			 <Fragment key={option.name+idx}>
	// 			 <PageGridItem col="1 / span 6">
	// 				 Name: {option.name}
	// 			 </PageGridItem>
	// 			 <PageGridItem col="1 / span 6">
	// 			 	Tickers: {option.tickers} 
	// 			 </PageGridItem>
	// 			 </Fragment>
	// 			 )
	// 		 })}

	// 	 </PageContentBodyGrid>
	//  </PageGridItem>
	// }

	// const dataset = (idx) => {
	// 	const {datasets} = templateDef;
	// 	const data = datasets[idx];
	// 	return null;
	// }

	if (notFound) {
		return <Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner />
			</PageContentBanner>
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<p>Invalid template</p>
			</PageContentBodyGrid>
		</Page>
	}

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
				handleBackOnClick={goBack}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the template details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<TemplateGeneralDetails template={templateDef} />
						<PageGridDivider col="1 / span 6" />
						{/* Publishing actions */}
						<TemplatePublishActions templateId={templateId} data={publishActions} addActionToTemplate={addActionToTemplate} removeAction={removeActionFromTemplate} selected={selectedPublishActions} setSelectedPublishActions={setSelectedPublishActions} />
						<PageGridDivider col="1 / span 6" />
						{/* Custom Publishing actions */}
						<TemplateCustomPublishActions />
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the sectors list section */}
				{templateDef && templateDef.ticker && templateDef.ticker.type==='selection' &&	<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						{/* Sectors */}
						<MiniSectorsList data={templateDef.ticker && templateDef.ticker.options } />
					</PageContentBodyGrid>
				</PageGridItem>}
			</PageContentBodyGrid>
		</Page>
   );
}

// const ParamField = ({param, template, action, setSelectedPublishActions}) => {

// 	const value = (action.paramValues && action.paramValues[param.name]) || "";
// 	const remote = useRemote();
// 	const [editor, setEditor] = useState(value);

// 	const update = () => {
// 		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`,{"value":editor}).then(setSelectedPublishActions);
// 	}

// 	return (
// 	<PageGridItem col="1 / span 3">
// 		<DetailField invalid={editor === "" ? "invalid" : undefined} editMode={true} autoFocus label={param.name} value={editor} onChange={(e) => setEditor(e.target.value)}/>
// 		<Button disabled={editor === value} onClick={update}>Save</Button>
// 	</PageGridItem>
// 	)
// };

export default withRouteChange(TemplateMgmt);