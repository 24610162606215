import React from 'react';
import "./Divider.scss";

const Divider = (props) => {
	const { thick, spacing, spacingTop, spacingBottom, noMargin, dashed, vertical, height, className, dark } = props;
	let classes = "divider";
	if (className) {
		classes += " " + className;
	}
	if (thick) {
		classes += " divider-thick";
	}
	if (dark) {
		classes += " divider-dark";
	}
	if (spacing) {
		classes += " spacing-" + spacing;
	}
	if (spacingTop) {
		classes += " spacing-top-" + spacingTop;
	}
	if (spacingBottom) {
		classes += " spacing-bottom-" + spacingBottom;
	}
	if (dashed) {
		classes += " divider-dashed";
	}
	if (vertical) {
		classes += " divider-vertical";
	}
	return (
		<hr className={classes} style={{ margin: noMargin ? 0 : null, height: vertical ? height : ""}} />
	)
}

export default Divider;