import React from 'react';
import "./DetailSectionHeader.scss";

const DetailSectionHeader = (props) => {
	const { header, icon } = props;
	return (
		<div className="detail-section-header">
			{icon && icon}
			<h3>{header}</h3>
		</div>
	)
}

export default DetailSectionHeader;