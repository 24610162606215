import React from 'react';
import ImageGallerySection from './ImageGallerySection';
import "./ImageGalleryWrapper.scss";

const ImageGalleryWrapper = ({onImageSelect, images, gap, size}) => {
	return (
		<div className={`image-gallery-wrapper${gap ? " image-gallery-wrapper-gap-" + gap : ""}`}>
			{images && images.map((section, idx) => <ImageGallerySection size={size} onImageSelect={onImageSelect} key={idx} images={section.images} header={"images" + idx} />)}
		</div>
	);
}

export default ImageGalleryWrapper;