import React from 'react';
import { SortableSidebarSection } from './SideBarSection';
import { useRemote } from '../../../Utils/Utils';
import TextInput from '../../Input/TextInput';
import arrayMove from "array-move";
import TickerGroupSelector from './TickerGroupSelector';
import TickerSelector from './TickerSelector';
import AlertBlock from '../../../layouts/Shared/Prompts/AlertBlock';
import Dropzone from "../../Dropzone/Dropzone";
import { TEMPLATE_TICKER_TYPES } from './EditorSideBarExtension';

const AssetSelectionSection = ({setState, state, updateTickerStatus, showModal, setLoadingLocalData, getDocumentIdentifier, setCompliance, setPeerReview, updateTickerGroup, templateTickerTypes}) => {
	const { selectedTickerGroup, tickers, tickerMessage, loadingLocalData, template, selectionGroups, selectedTemplate, uploading, showUploadingSpinner, uploadingMessage } = state
	const remote = useRemote();
	const isSelection = template && template.ticker.type === TEMPLATE_TICKER_TYPES.SELECTION;


	const reorderTicker = ({ oldIndex, newIndex }) => {
		document.body.style.cursor = 'default';
		setState((prevState) => {
			prevState.selectedTickerGroup.tickers = arrayMove(prevState.selectedTickerGroup.tickers, oldIndex, newIndex);
			prevState.tickers = prevState.selectedTickerGroup.tickers.filter(ticker => prevState.tickers.includes(ticker));
			return prevState;
		}, () => updateTickerStatus(true));
	};

	const onDrop = (files) => {
		setState({ uploading: true, showUploadingSpinner: true, uploadingMessage: "Uploading ..." });
		const tooBig = [];
		const wrongFileType = [];
		let anyUploaded = false;
		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let name = file.name;

			if (file.size > 5 * 1024 * 1024) {
				console.log("rejecting too big file ", file.size);
				tooBig.push(file);
				continue;
			}
			if (file.type === null || !file.type.includes("spreadsheetml")) {
				console.log("rejecting wrong file type file ", file.type);
				wrongFileType.push(file);
				continue;
			}

			console.log("Accepting file ", file.size, file.type, file.name);
			anyUploaded = true;
			let xhr = new XMLHttpRequest();
			// xhr.open('post', "/api/modules/crm/provider/" + this.props.location);
			xhr.open('post', "/api/data/upload", true);
			xhr.addEventListener('load', e => {
				// if (typeof data !== Object) {
				// 	data = JSON.parse(data);
				// }

				if (xhr.status >= 200 && xhr.status < 300) {
					onUploadComplete(e);
				} else {
					console.log(xhr);
				}
			});
			xhr.upload.addEventListener('progress', e => {
				if (e.lengthComputable) {
					let complete = (e.loaded / e.total * 100 | 0);
					if (complete === 100) {
						//TODO onscreen reporting
						console.log("Processing ...");
					} else {
						console.log("Finished");
					}
				}
			});

			xhr.setRequestHeader("Content-Type", file.type);
			xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
			xhr.setRequestHeader("X-3Skye-Session", remote.createTokenHeader().headers["X-3Skye-Session"]);
			xhr.send(file);
		}
		if (tooBig.length > 0 || wrongFileType.length > 0) {
			showModal("badProforma", () => { }, () => { }, { tooBig, wrongFileType });
			if (!anyUploaded) {
				setState({ uploading: false, showUploadingSpinner: false });
			}
		}
	}

	const onUploadComplete = (e) => {
		//Reload data, if we've got a ticker
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			showModal("uploadFailure");
			setState({ uploading: false, showUploadingSpinner: false, uploadingMessage: resp.message });
		} else {
			setState({ showUploadingSpinner: false, uploadingMessage: resp.message });
			setTimeout(() => setState({ uploading: false }), 1500);
			updateTickerStatus(true);
			onUpdateTickerGroup();
		}
	}

	const onUpdateTickerGroup = () => {
		console.log("onUpdateTickerGroup AssetSelectionSection");
		updateTickerGroup();
	}

	const onCheckboxTickerToggle = (ticker, checked) => {
		const newTickers = selectedTickerGroup.tickers.filter(tgTicker => tgTicker === ticker ? !checked : tickers.includes(tgTicker));
		setState({ tickers: newTickers }, updateTickerStatus);
	}

	const onTickerTextChange = (event, index) => {
		let value = event.currentTarget.value.toUpperCase();
		setState(({ tickers }) => ({ tickers: tickers.slice(0, index).concat(value).concat(tickers.slice(index + 1, tickers.length)) }));
	}

	const blurOnEnter = (e) => {
		if (e.key === 'Enter') {
			e.target.blur()
		}
	}

	return ( 
		<SortableSidebarSection
					separator
					onSortStart={() => (document.body.style.cursor = "grabbing")}
					onSortEnd={reorderTicker}
					helperClass="drag-item"
				>
					{isSelection ? (
						template.ticker.options ? (
							<TickerGroupSelector
								template={template}
								setState={setState}
								onUpdateTickerGroup={onUpdateTickerGroup}
								selectedTickerGroup={selectedTickerGroup}
								tickers={tickers}
								tickerMessage={tickerMessage}
								loadingLocalData={loadingLocalData}
								onCheckboxTickerToggle={onCheckboxTickerToggle}
							/>
						) : (
							<TickerSelector
								template={template}
								selectedTickerGroup={selectedTickerGroup}
								setState={setState}
								selectionGroups={selectionGroups}
								onUpdateTickerGroup={onUpdateTickerGroup}
							/>
						)
					) : (
						(template && template.ticker.type !== templateTickerTypes.FIXED) ? tickers.map((ticker, idx) => {
							// return <input key={idx} type="text" onChange={e => this.onTickerTextChange(e, idx)} onBlur={e => this.updateTickerStatus(true, true)} name="ticker" value={ticker && ticker} placeholder="Enter Ticker..." />
							return (
								<TextInput
									label="Ticker"
									// size="small"
									key={idx}
									onChange={(e) => onTickerTextChange(e, idx)}
									onBlur={(e) => updateTickerStatus(true)}
									onKeyDown={(e) => blurOnEnter(e)}
									name="ticker"
									value={ticker && ticker}
									placeholder="Enter Ticker..."
								/>
							);
						}) : null
					)}

					<p className="label-input mt-xl">Market Data Spreadsheet</p>

					<Dropzone
						onDrop={onDrop}
						uploading={uploading}
						showUploadingSpinner={showUploadingSpinner}
						uploadingMessage={uploadingMessage}
					/>
					{tickerMessage[tickers[0]] && (
						<AlertBlock
							justify="center"
							className="mt-m"
							type={tickerMessage[tickers[0]] !== "No data available" ? "positive" : "warning"}
							small
							fullWidth
						>
							{tickerMessage[tickers[0]]}
						</AlertBlock>
					)}
				</SortableSidebarSection>
	 );
}
 
export default AssetSelectionSection;