import React, { Component } from "react";

export default function (elementClassName, elementType) {
	return class ClassedElement extends Component {
		render() {
			let { children, className, forwardRef, ...otherProps } = this.props;
			className = [elementClassName, className].filter((cn) => !!cn).join(" ");
			return React.createElement(elementType, { className, ref: forwardRef, ...otherProps }, children);
		}
	};
}
