import React, { useEffect, useState } from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { useRemote, useStorage, minutesIntoReadableFormat, minutesIntoReadableFormatWithSeconds } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { User, Eye, Mail, Clock, ChevronDown, ChevronUp } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import TickerSectorItem from '../../../components/TickerSectorItem';
import PageContentToggle from '../../../components/Menus/PageContentToggle';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { useParams } from 'react-router-dom';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import LoadingIcon from '../../../components/LoadingIcon';
import { format, sub } from 'date-fns';
import TruncatedString from '../../Shared/TruncatedString'


const AnalyticsCompanyPage = ({ changeRoute }) => {
	const remote = useRemote()
	const storage = useStorage()
	const { companyId } = useParams()

	// State
	const [filteredData, setFilteredData] = useState([]);
	const [usersFilteredData, setUsersFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false);
	const [hasUserSearchTerm, setHasUserSearchTerm] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentView, setCurrentView] = useState("research");
	const [currentFilter, setCurrentFilter] = useState("");
	const [researchFilteredList, setResearchFilteredList] = useState([])
	const [usersFilteredList, setUsersFilteredList] = useState([])
	const [sortingColumn, setSortingColumn] = useState({ column: "Time/Date", ascending: false })
	const [researchDataDisplaying, setResearchDataDisplaying] = useState([])
	const [usersDataDisplaying, setUsersDataDisplaying] = useState([])

	const [data, setData] = useState([]);
	const [notFound, setNotFound] = useState(false)
	const [timeFrame, setTimeFrame] = useState({ months: 12 });
	const [notes, setNotes] = useState([])
	const [allTickers, setAllTickers] = useState([])
	const [allGroups, setAllGroups] = useState([])


	useEffect(() => {
		remote.get("/analytics/companies").then(d => {
			const thisCompany = d.filter(x => x.organisationId == companyId);		//eslint-disable-line eqeqeq
			if (!thisCompany || thisCompany.length < 1) {
				setNotFound(true);
			} else {
				setData(thisCompany[0]);
			}
		});

		storage.getOrFetch("/crm/tickers").then(setAllTickers)
		storage.getOrFetch(`/crm/groupings/${1}`).then((fetchedGrouping) => setAllGroups(fetchedGrouping.groups))

	}, [companyId])


	useEffect(() => {
		const today = new Date();
		// console.log("Subbing ", timeFrame)
		const start = sub(today, timeFrame);
		// console.log(today, start);
		remote.get(`/analytics/companies/${companyId}/notes?from=${format(start, 'yyyy-MM-dd')}&to=${format(today, 'yyyy-MM-dd')}`).then(setNotes);
	}, [timeFrame, companyId])


	// filters
	const filterOnclick = (value) => {
		return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
	}

	useEffect(() => {
		if (currentView === "research") {
			let newList = notes
			if (currentFilter === "pdf") newList = notes.filter(p => p.emailPdfDownloads || p.portalPdfDownloads)
			if (currentFilter === "model") newList = notes.filter(p => p.dataDownloads)
			if (currentFilter === "emailViews") newList = notes.filter(p => p.emailsRead)
			if (currentFilter === "portalViews") newList = notes.filter(p => p.portalViews)
			setResearchFilteredList(newList)

		} else {
			let newList = data.contacts
			if (currentFilter === "pdf") newList = data.contacts.filter(p => p.emailPdfDownloads || p.portalPdfDownloads)
			if (currentFilter === "model") newList = data.contacts.filter(p => p.dataDownloads)
			if (currentFilter === "emailViews") newList = data.contacts.filter(p => p.emailsRead)
			if (currentFilter === "portalViews") newList = data.contacts.filter(p => p.portalViews)
			setUsersFilteredList(newList)
		}
	}, [currentView, currentFilter, notes, data, sortingColumn])


	// Sorting
	useEffect(() => {
		sortTable(null)
	}, [currentView, filteredData, usersFilteredData, researchFilteredList, usersFilteredList, hasSearchTerm, hasUserSearchTerm])

	const sortTable = (column) => {
		if (column === null) {
			if (currentView === "research") setResearchDataDisplaying(hasSearchTerm ? filteredData : researchFilteredList)
			if (currentView === "users") setUsersDataDisplaying(hasUserSearchTerm ? usersFilteredData : usersFilteredList)
			return
		}

		let tableData = currentView === "research" && hasSearchTerm ?
			filteredData : currentView === "research" && !hasSearchTerm ?
				researchFilteredList : currentView === "watching" && hasUserSearchTerm ?
					usersFilteredData : usersFilteredList

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Time/Date":
				tableData.sort((a, b) => ascending ? a.publishedDate.localeCompare(b.publishedDate) : b.publishedDate.localeCompare(a.publishedDate))
				break;
			case "Ticker/Group":
				tableData.sort((a, b) => ascending ? a.tickerSymbol.localeCompare(b.tickerSymbol) : b.tickerSymbol.localeCompare(a.tickerSymbol))
				break;
			case "Title":
				tableData.sort((a, b) => ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title))
				break;
			case "Author":
				tableData.sort((a, b) => ascending ? a.analystName.localeCompare(b.analystName) : b.analystName.localeCompare(a.analystName))
				break;
			case "Minutes":
				tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
				break;
			case "Read":
				tableData.sort((a, b) => ascending ? a.emailsRead - b.emailsRead : b.emailsRead - a.emailsRead)
				break;
			case "PortalViews":
				tableData.sort((a, b) => ascending ? a.portalViews - b.portalViews : b.portalViews - a.portalViews)
				break;
			case "PDFDownloads":
				tableData.sort((a, b) => ascending ? (a.portalPdfDownloads + a.emailPdfDownloads) - (b.portalPdfDownloads + b.emailPdfDownloads) : (b.portalPdfDownloads + b.emailPdfDownloads) - (a.portalPdfDownloads + a.emailPdfDownloads))
				break;
			case "ModelDownloads":
				tableData.sort((a, b) => ascending ? a.dataDownloads - b.dataDownloads : b.dataDownloads - a.dataDownloads)
				break;
			case "Name":
				tableData.sort((a, b) => ascending ? a.contact.firstName.localeCompare(b.contact.firstName) : b.contact.firstName.localeCompare(a.contact.firstName))
				break;
			case "Email":
				tableData.sort((a, b) => ascending ? a.contact.email.localeCompare(b.contact.email) : b.contact.email.localeCompare(a.contact.email))
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		currentView === "research" ? setResearchDataDisplaying(tableData) : setUsersDataDisplaying(tableData)
	}


	// Research tab Table data
	const researchColWidths = ["150px", "200px", "260px", "70px", "70px", "70px", "70px", "70px"]
	const researchHeaders = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Time/Date")} >
					Time/Date
					{sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Ticker/Group")} >
					Ticker/Group
					{sortingColumn && sortingColumn.column === "Ticker/Group" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Ticker/Group" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Title")} >
					Title
					{sortingColumn && sortingColumn.column === "Title" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Title" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<Tooltip label="Minutes">
					<div className='sorting-header' onClick={() => sortTable("Minutes")}>
						<Clock className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Read">
					<div className='sorting-header' onClick={() => sortTable("Read")}>
						<Mail className="colour-text-purple" size={16} />
						{sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<div className='sorting-header' onClick={() => sortTable("PortalViews")}>
						<Eye className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
						<PdfIcon noHover className="cursor-pointer" size={16} />
						{sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
						<XlsIcon noHover className="cursor-pointer" size={16} />
						{sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		}
	]

	const researchDataMap = (data) => data && data.length > 0 && data.map((data, idx) => {
		return (
			// TODO: on row click go one level deeper 
			<tr key={idx} className="cursor-pointer" onClick={() => goToNote(data)}>
				{/* User details */}
				<td>{format(new Date(data.publishedDate), "dd/MM/yyyy")}</td>
				<td>
					<TickerSectorItem image={`/api/public/images/note/${data.noteId}.png`} label={data.tickerSymbol} />
				</td>
				<td style={{ paddingRight: "1.5rem" }}><TruncatedString string={data.title} /></td>
				<td className="text-align-center">{minutesIntoReadableFormat(data.minutes)}</td>
				<td className="text-align-center">{data.emailsRead}</td>
				<td className="text-align-center">{data.portalViews}</td>
				<td className="text-align-center">{data.portalPdfDownloads + data.emailPdfDownloads}</td>
				<td className="text-align-center">{data.dataDownloads}</td>
			</tr>
		)
	})

	// Users tab Table data
	const usersColWidths = ["280px", "280px", "70px", "70px", "70px", "70px", "70px"]
	const usersHeaders = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Email")} >
					Email
					{sortingColumn && sortingColumn.column === "Email" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Email" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<Tooltip label="Minutes">
					<div className='sorting-header' onClick={() => sortTable("Minutes")}>
						<Clock className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Read">
					<div className='sorting-header' onClick={() => sortTable("Read")}>
						<Mail className="colour-text-purple" size={16} />
						{sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Portal Views">
					<div className='sorting-header' onClick={() => sortTable("PortalViews")}>
						<Eye className="colour-text-primary" size={16} />
						{sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="PDF Downloads">
					<div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
						<PdfIcon noHover className="cursor-pointer" size={16} />
						{sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		},
		{
			text: (
				<Tooltip label="Model Downloads">
					<div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
						<XlsIcon noHover className="cursor-pointer" size={16} />
						{sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
							<ChevronUp className="colour-text-primary" size={12} /> :
							<ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
						}
					</div>
				</Tooltip>
			),
			className: "text-align-center"
		}
	]

	const usersDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/" + datum.contact.id)}>
				{/* User details */}
				<td>{datum.contact.firstName + ' ' + datum.contact.lastName}</td>
				<td>{datum.contact.email}</td>
				<td className="text-align-center">{minutesIntoReadableFormat(datum.minutes)}</td>
				<td className="text-align-center">{datum.emailsRead}</td>
				<td className="text-align-center">{datum.portalViews}</td>
				<td className="text-align-center">{datum.emailPdfDownloads + datum.portalPdfDownloads}</td>
				<td className="text-align-center">{datum.dataDownloads}</td>
			</tr>
		);
	});

	// Metrics
	const views = data && data.contacts && data.contacts.reduce((a, c) => c.portalViews + a, 0)
	const downloads = data && data.contacts && data.contacts.reduce((a, c) => c.emailPdfDownloads + c.portalPdfDownloads + a, 0)
	const emails = data && data.contacts && data.contacts.reduce((a, c) => c.emailsRead + a, 0)
	const dataDownloads = data && data.contacts && data.contacts.reduce((a, c) => c.dataDownloads + a, 0)
	const users = data && data.contacts && data.contacts.length
	const totalMinutes = data && data.contacts && data.contacts.reduce((a, c) => c.minutes + a, 0)

	// Change view
	function changeTab(string) {
		setCurrentFilter("")
		if (string === "research") {
			setCurrentView("research")
			setSortingColumn({ column: "Time/Date", ascending: false })
		}
		if (string === "users") {
			setCurrentView("users")
			setSortingColumn({ column: "Name", ascending: true })
		}

	}

	function goToNote(note) {
		if (note.tickerSymbol.includes(".")) {
			const ticker = allTickers && allTickers.find(t => t.ric === note.tickerSymbol)
			if (ticker) changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/" + ticker.id + "/research/" + note.noteId)
		} else {
			const group = allGroups.find(g => g.groupName === note.tickerSymbol)
			if (group) changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/group/" + group.id + "/research/" + note.noteId)
		}
	}


	if (notFound) {
		return <NoResultsMessage />
	}

	if (!data || !data.contacts) {
		return (
			<Page noBanner showScrollbar>
				<PageContentBodyGrid showScrollbar>
					<PageGridItem colSpan="12">
						<LoadingIcon centered />
					</PageGridItem>
				</PageContentBodyGrid>
			</Page>
		)
	}

	return (
		<Page noBanner showScrollbar>
			{/* Modal */}
			<PageContentBodyGrid showScrollbar>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="xl">
						<FlexWrapper gap="xs" direction="column" align="flex-start">
							<label>Company Name</label>
							<p className="font-weight-semi-bold">{data.organisationName}</p>
						</FlexWrapper>
						<Divider vertical height={40} />
						<AnalyticsMetricFilter
							onClick={() => setCurrentFilter("")}
							active={currentFilter === "minutes"}
							inactive={currentFilter !== "" && currentFilter !== "minutes"}
							label="Total Time"
							status="minutes"
							icon={<Clock className='colour-text-primary' />}
							count={!isNaN(totalMinutes) ? totalMinutes > 60 ? minutesIntoReadableFormat(totalMinutes) : minutesIntoReadableFormatWithSeconds(totalMinutes) : "-"}
							style={{ cursor: "default" }}
						/>
						<AnalyticsMetricFilter
							onClick={() => null}
							active={currentFilter === "users"}
							inactive={currentFilter !== "" && currentFilter !== "users"}
							label="Users"
							status="users"
							icon={<User />}
							count={users}
							style={{ cursor: "default" }}
						/>
						<AnalyticsMetricFilter
							onClick={() => filterOnclick("emailViews")}
							active={currentFilter === "emailViews"}
							inactive={currentFilter !== "" && currentFilter !== "emailViews"}
							label="Email Views"
							status="read"
							icon={<Mail />}
							count={emails}
						/>
						<Tooltip label="Total Views">
							<AnalyticsMetricFilter
								onClick={() => filterOnclick("portalViews")}
								active={currentFilter === "portalViews"}
								inactive={currentFilter !== "" && currentFilter !== "portalViews"}
								label="Portal Views"
								icon={<Eye />}
								count={views}
							/>
						</Tooltip>
						<Tooltip label="Total PDF Downloads">
							<AnalyticsMetricFilter
								onClick={() => filterOnclick("pdf")}
								active={currentFilter === "pdf"}
								inactive={currentFilter !== "" && currentFilter !== "pdf"}
								label="PDF Downloads"
								icon={<PdfIcon noHover />}
								count={downloads}
							/>
						</Tooltip>
						<Tooltip label="Total Model Downloads">
							<AnalyticsMetricFilter
								onClick={() => filterOnclick("model")}
								active={currentFilter === "model"}
								inactive={currentFilter !== "" && currentFilter !== "model"}
								label="Model Downloads"
								icon={<XlsIcon noHover />}
								count={dataDownloads}
							/>
						</Tooltip>
					</FlexWrapper>
				</PageGridItem>
				<PageGridDivider colSpan="12" />
				<PageGridItem colSpan="12">
					<FlexWrapper gap="m">
						<PageContentToggle labels={[
							{ label: "Research", active: currentView === "research", onClick: () => changeTab("research") },
							{ label: "Users", active: currentView === "users", onClick: () => changeTab("users") },
						]} />
						<Divider vertical height={24} />
						<SearchTermFilter
							size="small"
							width="l"
							placeholder={currentView === "research" ? `Search research ${loading ? "" : "(" + notes.length + ")"}` : `Search research ${loading ? "" : "(" + data.contacts.length + ")"}`}
							isClearable
							dataSet={currentView === "research" ? researchFilteredList : usersFilteredList}
							setFilteredData={currentView === "research" ? setFilteredData : setUsersFilteredData}
							path={currentView === "research" ? ["tickerSymbol", "tickerName", "title"] : ["contact.firstName", "contact.lastName", "contact.email"]}
							hasSearchTerm={(boolean) => currentView === "research" ? setHasSearchTerm(boolean) : setHasUserSearchTerm(boolean)}
						/>
					</FlexWrapper>
				</PageGridItem>
				<PageGridItem colSpan="12" style={{ paddingTop: "0.5rem" }}>
					<Table
						minWidth={1000}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={currentView === "research" ? researchColWidths : usersColWidths}
						headers={currentView === "research" ? researchHeaders : usersHeaders}
						dataMap={currentView === "research" ? researchDataMap(researchDataDisplaying) : usersDataMap(usersDataDisplaying)}
						noResults={{ message: "No results found" }}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(AnalyticsCompanyPage);