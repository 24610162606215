import React from 'react';
import { Copy, Download, Edit2, Trash2, Save, X } from 'react-feather';
import TableActionIcon from './TableActionIcon';

const TableActionIconGroup = ({ edit, duplicate, remove, download, disabled, save, cancel }) => {
	return (
		<td className={`table-action-icons${disabled ? " action-icons-disabled" : ""}`}>
			{!edit || (edit && edit.hide) ? "" : (
				<TableActionIcon icon={edit && edit.icon ? edit.icon : <Edit2 />} toolTip={edit && edit.toolTip ? edit.toolTip : "Edit"} onClick={edit && edit.onClick} disabled={edit && edit.disabled} />
			)}
			{!save || (save && save.hide) ? "" : (
				<TableActionIcon icon={save && save.icon ? save.icon : <Save />} toolTip={save && save.toolTip ? save.toolTip : "Save"} onClick={save && save.onClick} disabled={save && save.disabled} />
			)}
			{!duplicate || (duplicate && duplicate.hide) ? "" : (
				<TableActionIcon icon={duplicate && duplicate.icon ? duplicate.icon : <Copy />} toolTip={duplicate && duplicate.toolTip ? duplicate.toolTip : "Duplicate"} onClick={duplicate && duplicate.onClick} disabled={duplicate && duplicate.disabled} />
			)}
			{!remove || (remove && remove.hide) ? "" : (
				<TableActionIcon hover="danger" icon={remove && remove.icon ? remove.icon : <Trash2 />} toolTip={remove && remove.toolTip ? remove.toolTip : "Delete"} onClick={remove && remove.onClick} disabled={remove && remove.disabled} />
			)}
			{!download || (download && download.hide) ? "" : (
				<TableActionIcon icon={download && download.icon ? download.icon : <Download />} toolTip={download && download.toolTip ? download.toolTip : "Download"} onClick={download && download.onClick} disabled={download && download.disabled} />
			)}
			{!cancel || (cancel && cancel.hide) ? "" : (
				<TableActionIcon icon={cancel && cancel.icon ? cancel.icon : <X />} toolTip={cancel && cancel.toolTip ? cancel.toolTip : "Cancel"} onClick={cancel && cancel.onClick} disabled={cancel && cancel.disabled} />
			)}
		</td>
	);
}

export default TableActionIconGroup;