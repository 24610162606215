import React, { Component } from "react";
// import AsyncSelect from 'react-select/lib/Async';
import Async from "react-select/async";
import { withI18n, withThreeSkyeGlobal } from "@threeskye/global";
// import "../../Validation/ValidationInput.scss";
import { retrievePhonePrefixList } from "../../../Utils/validationUtils";
import _ from "lodash";
import "./PhoneNumberEdit.scss";
class PhoneNumberEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			prefixValue: null,
			valid: true,
			phoneFocused: false
		};
		this.validate = this.validate.bind(this);
		this.handleNumberChange = this.handleNumberChange.bind(this);
		this.handlePrefixChange = this.handlePrefixChange.bind(this);
		this.handleExtChange = this.handleExtChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.focusNumber = this.focusNumber.bind(this);
		this.numberRef = React.createRef();
	}

	componentDidMount() {
		this.updatePrefixValue();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.prefixValue || _.get(this.props.value, "idd") !== _.get(prevProps.value, "idd")) {
			this.updatePrefixValue();
		}
	}

	updatePrefixValue() {
		var idd = _.get(this.props.value, "idd");
		
		if (!idd)
			return;
		retrievePhonePrefixList("" + idd, this.props.remote).then(iddList => {
			return (iddList.length > 0 ? this.setState({ prefixValue: iddList[0] }) : null);
		});
	}

	/**
	 * Checks if the new inputValue is valid,
	 * If it is, set error state false and call our onChange prop
	 * If it is not, set error state true, do not call onChange
	 * In all cases,  update our inputValue in the state.
	 * @param {String} inputValue 
	 */
	handleNumberChange(input) {
		this.handleChange(this.state.prefixValue, input.target.value, _.get(this.props.value, "extension"));
	}

	handlePrefixChange(newValue) {
		this.handleChange(newValue, _.get(this.props.value, "number"), _.get(this.props.value, "extension"));
		this.focusNumber();
	}

	handleExtChange(event) {
		this.handleChange(this.state.prefixValue, _.get(this.props.value, "number"), event.target.value);
	}

	handleChange(prefix, number, extension) {
		const isValid = this.validate(prefix, number, extension);
		const data = { idd: prefix && prefix.value, number, extension, valid: isValid };
		if (this.props.onChange) {
			this.props.onChange(data);
		}
		if (this.props.onValidChange && isValid) {
			this.props.onValidChange(data);
		}
		this.setState({ valid : isValid});
	}

	/**
	 * Wrapper method for prop method
	 * */
	validate(prefix, number, extension) {
		if (!(prefix && prefix.value)) {
			return false;
		}
		// TODO: do we need to use the default values here?
		// number = number || "";
		// extension = extension || "";

		if (this.props.validate) {
			return this.props.validate({ idd: prefix.value, number, extension });
		} else if (this.props.validateAsString) {
			return this.props.validateAsString(prefix.value.concat(number, extension));
		}
		return true;
	}

	renderPrefix(prefix) {
		return <span className="prefix-suggestion">
			<span className="prefix-label" style={{marginRight: 8}}>
				{prefix.label}
			</span>
			<span className="prefix-value">
				{prefix.value}
			</span>
		</span>;
	}

	focusNumber() {
		this.numberRef.current.focus();
	}

	render() {
		const { number } = this.props.value ? this.props.value : {};
		let { valid } = this.state;
//		const i = this.props.i18n.get;

		if (this.state.prefixValue && this.state.phoneFocused) {
			valid = true;
		}

		return (
			<>
				<div className={`phone-number-edit-container${valid ? "" : " phone-field-invalid"}`}>
					{/*<label htmlFor={this.props.name}>{this.props.label}</label>*/}
					<Async
						isMulti={false}
						isClearable={false}
						value={this.state.prefixValue}
						onChange={this.handlePrefixChange}
						components={{
							IndicatorSeparator: () => null,
							LoadingIndicator: () => null,
							DropdownIndicator: () => null,
						}}
						cacheOptions
						defaultOptions
						loadOptions={this.props.retrievePrefixList}
						placeholder={this.props.placeholderIDD}
						className={`search-select-field phone-select-field${valid ? "" : " input-invalid"}`}
						classNamePrefix="search-select-field"
						getOptionLabel={this.renderPrefix}
						getOptionValue={this.props.getOptionValue || ((o) => JSON.stringify(o))}
						autoFocus={this.props.autoFocus}
						menuPosition="fixed"
					/>
					<input
						type={this.props.type}
						name={this.props.name}
						placeholder={this.props.placeholderValue}
						className={"phone-number-field" + (valid ? "" : " input-validation-text-invalid")}
						onChange={this.handleNumberChange}
						value={number}
						ref={this.numberRef}
						onFocus={() => this.setState({ phoneFocused: true })}
						onBlur={() => this.setState({ phoneFocused: false })}
					/>
					{/* {this.props.hasExtension && (
						<input
							type="text"
							name="extension"
							className="extension-input"
							placeholder={this.props.placeholderExt}
							onChange={this.handleExtChange}
							value={extension||""}
						/>
					)} */}
					{/* {valid ? "" : (
					<div className="input-validation-warning-message">
						<div className="input-validation-warning-icon">
							<i className="material-icons validation-error">error</i>
						</div>
						<p>{!this.state.prefixValue ? this.props.noPrefixMessage : this.props.invalidNumberMessage}</p>
					</div>
				)} */}
				</div>
				{!valid && !this.state.phoneFocused && <div className="text-input-helper-text-invalid">{!this.state.prefixValue ? this.props.noPrefixMessage : this.props.invalidNumberMessage}</div>}
			</>
		);
	}
}

export default withThreeSkyeGlobal(withI18n(PhoneNumberEdit));