import React from 'react';
import IconLink from '../Menus/IconLink';
import withRouteChange from '@threeskye/route-change';
import { withThreeSkyeGlobal } from '@threeskye/global';
import { Tool, UserCheck, Database, Save, TrendingUp, List } from 'react-feather';
import SideBarWrapper, { SideBarIconGroup } from './SideBarWrapper';
import "./SideBarRight.scss";

const SideBarRight = ({ location, changeRoute, storage, setSideBarExtRight, sideBarExtRight }) => {
	const checkRoute = (route) => {
		return route === "/" ? location.pathname === route : location.pathname.startsWith(route)
	}

	return (
		<SideBarWrapper borderLeft right>
			<SideBarIconGroup>
				<IconLink
					icon={<UserCheck />}
					onClick={() => {
						setSideBarExtRight("Comments", true)
					}}
					active={sideBarExtRight === "Comments"}
				/>
				<IconLink
					icon={<Tool />}
					onClick={() => {
						setSideBarExtRight("Options", true)
					}}
					active={sideBarExtRight === "Options"}
					label="Options"
				/>
				<IconLink
					icon={<Save />}
					onClick={() => {
						setSideBarExtRight("Versions", true)
					}}
					active={sideBarExtRight === "Versions"}
					label="History"
				/>
				<IconLink
					icon={<Database />}
					onClick={() => {
						setSideBarExtRight("Data", true)
					}}
					active={sideBarExtRight === "Data"}
					label="Data"
				/>
				<IconLink
					icon={<List />}
					onClick={() => {
						setSideBarExtRight("Model", true)
					}}
					active={sideBarExtRight === "Model"}
					label="Model"
				/>
			</SideBarIconGroup>
		</SideBarWrapper>
	)
};
export default withThreeSkyeGlobal(withRouteChange(SideBarRight));