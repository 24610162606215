import React from 'react';
import DropDown from '../../DropDown';
import FlexWrapper from '../../../layouts/FlexWrapper';
import TickerItem from '../../TickerItem';

const TickerGroupSelector = ({template, setState, onUpdateTickerGroup, selectedTickerGroup, tickers, tickerMessage, loadingLocalData, onCheckboxTickerToggle}) => {
	return (
		<>
			{/* Sector Dropdown */}
			<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedTickerGroup && selectedTickerGroup.name}>
				{template.ticker.options.map((option, index) => (
					<li key={index} onClick={() => setState({ selectedTickerGroup: option }, onUpdateTickerGroup)}>
						{option.name}
					</li>
				))}
			</DropDown>
			{selectedTickerGroup ? (
				<FlexWrapper className="mt-l" wrap gap="m">
					{selectedTickerGroup.tickers.map((ticker, idx) => {
						const checked = tickers.includes(ticker);
						const hasData = !!tickerMessage[ticker] && tickerMessage[ticker] !== "No data available";
						// Dummy const for old data
						const dataOutOfDate = "";
						return (
							<TickerItem
								tickerStatus={hasData ? "data-available" : dataOutOfDate ? "data-out-of-date" : "data-unavailable"}
								key={idx}
								index={idx}
								ticker={ticker}
								tickerMessage={tickerMessage[ticker]}
								hasData={hasData}
								checked={checked}
								loading={loadingLocalData}
								onChange={() => onCheckboxTickerToggle(ticker, checked)}
							/>
						);
					})}
				</FlexWrapper>
			) : null}
		</>
	);
}

export default TickerGroupSelector;