import React from 'react';
import FlexWrapper from '../../layouts/FlexWrapper';
import "./PageContentToggle.scss";

const PageContentToggle = ({labels}) => {
	return (
		<FlexWrapper gap="l" className="page-content-toggle">
			{labels && labels.map((label, idx) => {
				return (
					<div key={idx} className={`toggle-label${label.active ? " toggle-label-active" : ""}`} onClick={label.onClick}>
						{label.label}
					</div>
				)
			})}
		</FlexWrapper>
   );
}

export default PageContentToggle;