import React from 'react';
import { CreditCard } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';

const CompanyBillingInformation = ({ editMode, company }) => {
	return (
		<React.Fragment>
			<PageGridItem disabled col="1 / span 6">
				<DetailSectionHeader header="Billing Information" icon={<CreditCard />} />
			</PageGridItem>
			<PageGridItem disabled col="1 / span 3">
				<DetailField
					// editMode={editMode}
					label="Bill Cycle"
					value={ company.billingCycle && company.billingCycle }
				/>
			</PageGridItem>
			<PageGridItem disabled col="4 / span 3">
				<DetailField
					// editMode={editMode}
					label="Billed To"
					value={ company.billedTo && company.billedTo }
				/>
			</PageGridItem>
			<PageGridItem disabled col="1 / span 3">
				<DetailField
					// editMode={editMode}
					label="Billing Address"
					value={ company.billingAddress && company.billingAddress }
				/>
			</PageGridItem>
			<PageGridItem disabled col="4 / span 3">
				<DetailField
					// editMode={editMode}
					label="Amount"
					value={ company.billingInformation && company.billingInformation }
				/>
			</PageGridItem>
			<PageGridItem disabled col="1 / span 3">
				<DetailField
					// editMode={editMode}
					label="Next Payment"
					value={ company.billedTo && company.billedTo }
				/>
			</PageGridItem>
		</React.Fragment>
	);
}

export default CompanyBillingInformation;