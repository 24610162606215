import React, { useRef, useState } from 'react';
import FlexWrapper from '../../FlexWrapper';
import TickerSectorImage from '../../../components/TickerSectorImage';
import "./PublicationPreview.scss";
import Divider from '../../../components/Divider';
import StatusToken from '../../../components/StatusTokens';
import TextArea from '../../../components/Input/TextArea';
import { Edit2 } from 'react-feather';

const PublicationPreview = ({ displayName, title, summary, formattedDate, analystName, tickers, imageUrl, imageUrlLoading, onImageClick, hoverUpload, onSummaryChange, editable }) => {

	const textAreaRef = useRef()
	const initialFocus = useRef(true)
	const [textAreaHeight, setTextAreaHeight] = useState(50)

	const autoGrow = (element, extraSpace) => {
		const height = extraSpace ? element.scrollHeight + 10 : element.scrollHeight
		if (height > textAreaHeight) {
			setTextAreaHeight(height)
		}
		initialFocus.current = false
	}

	const handleTextChange = (summary) => {
		onSummaryChange(summary)
	}

	return (
		<FlexWrapper className='research-list-row' fullWidth justify="space-between" >
			<FlexWrapper className="research-content-wrapper" wrap gap="m" style={{ width: "90%" }}>
				{/* header */}
				<div style={{ width: "100%", minHeight: "0.85rem" }} className="research-ticker-name">
					{displayName}
				</div>
				{/* main */}
				<FlexWrapper gap="xs" wrap className="research-info">
					<div style={{ width: "100%" }} className="research-title">
						{title}
					</div>
					<div style={{ width: "100%" }} className="research-preview">
						<TextArea
							disabled={!editable}
							onFocus={() => autoGrow(textAreaRef.current, initialFocus.current === true)}
							onInput={() => autoGrow(textAreaRef.current)}
							value={summary}
							style={{ height: (textAreaHeight) + "px", overflow: "hidden", minHeight: "30px" }}
							forwardRef={textAreaRef}
							onChange={(e) => handleTextChange(e.target.value)}
							maxlength={300}
						/>
					</div>
				</FlexWrapper>
				{/* details */}
				<FlexWrapper className="research-details" fullWidth gap="s">
					<div className="research-ticker-date">{formattedDate}</div>
					<Divider height="10px" vertical flexItem />
					<div className="research-ticker-author">{analystName}</div>
					<Divider height="10px" vertical flexItem />
					{tickers && tickers.map((ticker, idx) => <StatusToken key={idx}>{ticker}</StatusToken>)}
				</FlexWrapper>
			</FlexWrapper>
			{/* Logo */}
			<div className="research-ticker-logo">
				<TickerSectorImage removeOverlay={!hoverUpload} hoverUpload={hoverUpload} hoverIcon={<Edit2 />} onClick={onImageClick} image={imageUrl} imageLoading={imageUrlLoading} size="xxl" />
			</div>
		</FlexWrapper>
	);
}

export default PublicationPreview;