import React from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import "./AlertBlock.scss";

const AlertBlock = (props) => {
	const { icon, hideIcon, message, children, type, fullWidth, header, spacing, small, className, justify } = props;
	return (
		<div className={`alert-block-wrapper${className ? ' ' + className : ''}${small ? " alert-size-small" : ""}${spacing ? " alert-spacing-" + spacing : ""}${type ? " alert-type-" + type : ""}${header ? " alert-type-has-header" : ""}`} style={{width: fullWidth ? "100%" : null, justifyContent: justify}}>
			{/* Icon */}
			{hideIcon ? "" : <div className="alert-block-icon">{icon ? icon : type === "positive" ? <CheckCircle /> : type === "warning" || type === "danger" ? <AlertCircle /> : ""}</div>}
			{/* Message */}
			<div className="alert-block-message">
				{header && <h4>{type === "positive" ? "Success" : type === "warning" ? "Warning" : type === "danger" ? "Error" : "Alert"}</h4>}
				{message || children}
			</div>
		</div>
	)
}

export default AlertBlock;