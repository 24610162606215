import React, { Component } from "react";
import { PageContentBanner } from "../Shared/Page/Page";
import DateRangeInput from "../CraigsLibraryPage/DateRangeInput";
import { withCraigsDocsFilters } from "../CraigsLibraryPage/withCraigsDocs";
import TickerListSearchField from "../../components/Input/TickerListSearchField";
import moment from "moment";

const DraftsLibraryPageContentBanner = ({ filters, setFilters }) => {
	const { ticker, from, to } = filters;
	return (
		<PageContentBanner divider gap="m">
			<TickerListSearchField
				minWidth
				size="small"
				placeholder="Search"
				value={ticker}
				onChange={(ticker) => setFilters({ ...filters, ticker })}
				isClearable
				onClear={() => setFilters({ ...filters, ticker: null })}
			/>
			<DateRangeInput value={{ from, to }} onChange={({ from, to }) => setFilters({ ...filters, from: from ? moment(from).toISOString() : "", to: to ? moment(to).toISOString() : "" })} />
		</PageContentBanner>
	);
}

export default withCraigsDocsFilters(DraftsLibraryPageContentBanner);