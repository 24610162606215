import React from "react";
import useWindowDimensions from "../../../Utils/Utils";
import "./PageGridItem.scss";


const PageGridItem = (props) => {
	const { col, colSpan, colStart, colEnd, row, rowSpan, rowStart, rowEnd, fullWidth, children, className, container, justifyContent, alignItems, gap, wrap, showScrollbar, disabled, style, direction } = props;
	const { width } = useWindowDimensions();

	// Col styles
	const colStyle =
		fullWidth || col || colSpan
			? {
					gridColumn:
						fullWidth || width < 900 || (container && width < 1200)
							? "1 / span 12"
							: col
							? col
							: colStart && colSpan
							? colStart + " / span " + colSpan
							: colSpan
							? "span " + colSpan
							: "",
			  }
			: "";
	const colStartStyle = colStart
		? {
				gridColumnStart: colStart ? colStart : "",
		  }
		: "";
	const colEndStyle = colEnd
		? {
				gridColumnEnd: colEnd ? colEnd : "",
		  }
		: "";
	
	// Row styles
	const rowStyle =
		row || rowSpan
			? {
					gridRow: row
						? row
						: rowStart && rowSpan
						? rowStart + " / span " + rowSpan
						: rowSpan
						? "span " + rowSpan
						: "",
			  }
			: "";
	const rowStartStyle = rowStart
		? {
				gridRowStart: rowStart ? rowStart : "",
		  }
		: "";
	const rowEndStyle = rowEnd
		? {
				gridRowEnd: rowEnd ? rowEnd : "",
		  }
		: "";

	return (
		<div
			style={{ ...style, ...colStyle, ...colStartStyle, ...colEndStyle, ...rowStyle, ...rowStartStyle, ...rowEndStyle }}
			className={`page-grid-item${justifyContent ? " justify-content-" + justifyContent : ""}${alignItems ? " align-items-" + alignItems : ""}${
				container ? " page-grid-item-container" : ""
			}${className ? " " + className : ""}${gap ? " gap-" + gap : ""}${wrap ? " wrap" : ""}${showScrollbar ? "" : " hide-scrollbar"}${disabled ? " item-disabled" : ""}${direction ? " flex-direction-" + direction : ""}`}
		>
			{children}
		</div>
	);
};

export default PageGridItem;
