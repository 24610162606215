import React, { Component } from "react";
import { Search } from "react-feather";
import SearchField from "./SearchField";
import "./SelectSearchField.scss";

class SelectSearchField extends Component {
	render() {
		return (
			<div className="search-field-with-icon"><Search className="search-icon" /><SearchField icon {...this.props} /></div>
		);
	}
}

export default SelectSearchField;
