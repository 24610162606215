import React, { Component } from "react";
import NoResultsMessage from "./EmptyMessage/NoResultsMessage";
import LoadingIcon from "./LoadingIcon";

class RemoteData extends Component {
	render() {
		const { isLoading, data, children } = this.props;
		const noData = !data || (Array.isArray(data) && data.length === 0);
		if (noData && isLoading) {
			return <LoadingIcon centered size={40} />;
		} else if (noData) {
			console.log("Remote data no data", data)
			return <NoResultsMessage />;
		}
		return (
			<>
				{children}
				{isLoading && <LoadingIcon centered size={40} />}
			</>
		);
	}
}

export default RemoteData;
